<template>
  <div>
    <b-row class="mt-4">
      <div class="d-flex justify-content-between">
        <b-card-title class="mb-2 py-2">
          {{ $t('dashboards.users') }}
        </b-card-title>
        <b-button @click="openModal()" variant="outline-dark">
          {{ $t('survey.new') }}
        </b-button>
      </div>
      <b-colxx xxs="12">
        <b-card no-body>
          <b-card-text>
            <b-table
              :fields="tagUsersTable.fields"
              :items="tagUsers"
              responsive
              hover
              show-empty
              :empty-text="$t('validation.empty_users')"
            >
              <template #cell(name)="data">
                {{ data.item.customuser.first_name }}
                {{ data.item.customuser.last_name }}
              </template>
              <template #cell(action)="data">
                <div class="text-center">
                  <b-button
                    pill
                    class="mx-1"
                    size="sm"
                    variant="info"
                    @click="openDetails(data.item.id)"
                  >
                    <i class="simple-icon-eye"></i>
                  </b-button>
                  <b-button
                    pill
                    class="mx-1"
                    size="sm"
                    variant="success"
                    @click="openModal(true, data.item.id)"
                  >
                    <i class="simple-icon-pencil"></i>
                  </b-button>
                  <b-button
                    pill
                    class="mx-1"
                    size="sm"
                    variant="danger"
                    @click="openDeleteDialog(data.item.id)"
                  >
                    <i class="simple-icon-trash"></i>
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
      </b-colxx>
    </b-row>
    <b-modal
      id="modal-tag-user"
      :title="$t('pages.users.details')"
      modal-class="modal-right"
      no-body
      @close="closeModalDetails"
      no-close-on-backdrop
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-colxx xss="12" class="mt-3">
            <p class="text-muted text-small mb-2">Nombre</p>
            <p class="mb-3">
              {{ getUserById(tagUser.customuser.id).first_name }}
              {{ getUserById(tagUser.customuser.id).last_name }}
            </p>
            <p class="text-muted text-small mb-2">Email</p>
            <p class="mb-3">
              {{ getUserById(tagUser.customuser.id).email }}
            </p>
            <p
              v-if="getUserById(tagUser.customuser.id).phone"
              class="text-muted text-small mb-2"
            >
              Telefono
            </p>
            <p v-if="getUserById(tagUser.customuser.id).phone" class="mb-3">
              {{ getUserById(tagUser.customuser.id).phone }}
            </p>
            <p
              v-if="getUserById(tagUser.customuser.id).address"
              class="text-muted text-small mb-2"
            >
              Dirección
            </p>
            <p v-if="getUserById(tagUser.customuser.id).address" class="mb-3">
              {{ getUserById(tagUser.customuser).address }}
            </p>
          </b-colxx>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      id="modal-tag-users"
      :title="
        modeEditor
          ? `${$t('survey.edit')} ${$t('user.user')}`
          : `${$t('forms.add')} ${$t('user.user')}`
      "
      modal-class="modal-right"
      @close="closeModal"
      no-close-on-backdrop
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-colxx xss="12" class="mt-3">
            <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
              <b-form-group :label="$t('user.user')" class="w-100 has-float-label mb-4">
                <b-form-select
                  v-model="$v.form.customuser.$model"
                  :state="!$v.form.customuser.$error"
                  class="w-100"
                  :options="
                    users.filter(
                      (user) =>
                        !tagUsers.some((tagUser) => tagUser.customuser === user.id) ||
                        modeEditor
                    )
                  "
                  value-field="id"
                  text-field="fullname"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      Seleccione...
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback v-if="!$v.form.customuser.required">
                  El usuario es requerido.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-card v-if="form.customuser" no-body class="mb-4">
                <b-container>
                  <b-row>
                    <b-colxx xss="12" class="mt-3">
                      <p class="text-muted text-small mb-2">Nombre</p>
                      <p class="mb-3">
                        {{ getUserById(form.customuser).first_name }}
                        {{ getUserById(form.customuser).last_name }}
                      </p>
                      <p class="text-muted text-small mb-2">Correo</p>
                      <p class="mb-3">
                        {{ getUserById(form.customuser).email }}
                      </p>
                      <p
                        v-if="getUserById(form.customuser).phone"
                        class="text-muted text-small mb-2"
                      >
                        Telefono
                      </p>
                      <p v-if="getUserById(form.customuser).phone" class="mb-3">
                        {{ getUserById(form.customuser).phone }}
                      </p>
                    </b-colxx>
                  </b-row>
                </b-container>
              </b-card>
              <div class="d-flex justify-content-end">
                <b-button
                  type="submit"
                  variant="outline-dark"
                  size="md"
                  :disabled="processing"
                  :class="{
                    'btn-multiple-state btn-shadow': true,
                    'show-spinner': processing
                  }"
                >
                  <span class="spinner d-inline-block">
                    <span class="bounce1"></span>
                    <span class="bounce2"></span>
                    <span class="bounce3"></span>
                  </span>
                  <span class="label">
                    {{ $t('survey.save') }}
                  </span>
                </b-button>
              </div>
            </b-form>
          </b-colxx>
        </b-row>
      </b-container>
    </b-modal>
    <BaseConfirmModal
      dialogId="delete-tag-user"
      :title="`${$t('pages.delete')} ${$t('user.user')}`"
      :message="`¿ ${$t('general.question_delete')} ${$t('user.user')} ?`"
      :cancelText="$t('pages.cancel')"
      :proccedText="$t('button.accept')"
      @close="closeDeleteDialog"
      @procced="proccedDelete"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import adminMixin from '@/mixins/admin'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')

export default {
  name: 'tagsUsers',
  components: {
    BaseConfirmModal
  },
  mixins: [validationMixin, adminMixin],
  data() {
    return {
      selectedToDelete: null,
      processing: false,
      modeEditor: false,
      form: {
        id: null,
        customuser: null,
        tag: null
      },
      tagUsersTable: {
        fields: [
          {
            key: 'name',
            label: 'Nombre',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'action',
            label: 'Acción',
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          }
        ]
      },
      selectedImg: null
    }
  },
  validations: {
    form: {
      customuser: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      tag: 'tag/tag',
      tagUser: 'tag/tagUser',
      tagUsers: 'tag/tagUsers',
      users: 'project/users'
    })
  },
  methods: {
    ...mapActions({
      getTagUsers: 'tag/getTagUsers',
      getTagUser: 'tag/getTagUser',
      createTagUser: 'tag/createTagUser',
      updateTagUser: 'tag/updateTagUser',
      deleteTagUser: 'tag/deleteTagUser'
    }),
    ...mapMutations({ setTagUser: 'tag/setTagUser' }),
    openModal(edit = false, itemId = null) {
      this.modeEditor = edit
      if (itemId) {
        const data = this.tagUsers.find((item) => item.id === itemId)
        const { id, customuser, tag } = data
        this.form = {
          id,
          customuser,
          tag
        }
      }
      this.$bvModal.show('modal-tag-users')
    },
    closeModal() {
      this.modeEditor = false
      this.$nextTick(() => {
        this.form = {
          id: null,
          customuser: null,
          tag: null
        }
        this.$v.form.$reset()
        this.$bvModal.hide('modal-tag-users')
      })
    },
    async openDetails(itemId) {
      const success = await this.getTagUser({
        id: itemId
      })
      if (success) {
        this.$bvModal.show('modal-tag-user')
      }
    },
    closeModalDetails() {
      this.setTagUser({
        customuser: {}
      })
      this.$bvModal.hide('modal-tag-user')
    },
    async formSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { id, ...rest } = this.form
        let success
        if (id) {
          success = await this.updateTagUser({
            id,
            body: {
              ...rest
            }
          })
        } else {
          success = await this.createTagUser({
            body: {
              ...rest,
              tag: this.tag.id
            }
          })
        }
        this.processing = false
        if (success) {
          this.closeModal()
        }
      }
    },
    openDeleteDialog(itemId) {
      this.selectedToDelete = itemId
      this.$bvModal.show('delete-tag-user')
    },
    closeDeleteDialog() {
      this.selectedToDelete = null
      this.$bvModal.hide('delete-tag-user')
    },
    async proccedDelete() {
      const success = await this.deleteTagUser({
        id: this.selectedToDelete,
        tagId: this.tag.id
      })
      if (success) {
        this.closeDeleteDialog()
      }
    }
  }
}
</script>

<style></style>
