<template functional>
  <b-img :src="props.icon" style="width: 0.65rem; height: 1rem; color: white"></b-img>
</template>

<script>
export default {
  name: 'icon',
  props: {
    icon: {
      type: String,
      required: true
    }
  }
}
</script>

<style></style>
