<template>
  <Layout>
    <div class="p-4">
      <b-row class="mt-4">
        <div class="d-flex justify-content-between">
          <div class="ml-2">
            <h1>{{ $t('menu.equipments') }}</h1>
          </div>
          <div class="mr-3">
            <b-button @click="openModal()" variant="outline-dark"> Nuevo </b-button>
          </div>
        </div>
        <div class="separator mb-3"></div>
      </b-row>
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <b-card-text>
              <b-row class="d-flex justify-content-between flex-wrap my-3">
                <b-col cols="4">
                  <b-form-group
                    label-align-sm="left"
                    label-for="filterInput"
                    class="mb-0 ml-3 pa-2"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Buscar equipos"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group
                    label-cols="5"
                    label="Mostrar"
                    label-align="left"
                    label-size="default"
                    label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-2"
                  >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      inline
                      :options="pageOptions"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                :fields="tableEquipments.fields"
                :items="equipments"
                :per-page="perPage"
                :current-page="currentPage"
                responsive
                hover
                show-empty
                empty-text="No hay equipos registrados."
                empty-filtered-text="No hay equipos para filtrar."
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="ml-3"
              >
                <template #cell(name)="data">
                  <img
                    class="img__avatar mx-1"
                    width="50"
                    v-if="data.item.image"
                    :src="data.item.image"
                  />
                  <span class="ml-2">
                    {{ data.value }}
                  </span>
                </template>
                <template #cell(trademark)="data">
                  {{ getTrademarkById(data.value) }}
                </template>
                <template #cell(action)="data">
                  <div class="text-center">
                    <b-button
                      pill
                      size="sm"
                      class="mx-1"
                      variant="info"
                      @click="openDetails(data.item.id)"
                    >
                      <i class="simple-icon-eye"></i>
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      class="mx-1"
                      variant="success"
                      @click="openModal(true, data.item.id)"
                    >
                      <i class="simple-icon-pencil"></i>
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      class="mx-1"
                      variant="danger"
                      @click="openDeleteDialog(data.item.id)"
                    >
                      <i class="simple-icon-trash"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
              <b-row class="d-flex justify-content-center align-items-center flex-wrap pt-0">
                <div>
                  <b-pagination
                    pills
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="table-users"
                    class="ml-2"
                  ></b-pagination>
                </div>
              </b-row>
            </b-card-text>
          </b-card>
        </b-colxx>
      </b-row>
      <b-modal
        id="modal-equipments"
        :title="modeEditor ? 'Editar Equipo' : 'Agregar Equipo'"
        modal-class="modal-right"
        @close="closeModal"
        no-close-on-backdrop
        hide-footer
      >
        <b-container fluid>
          <b-row>
            <b-colxx xxs="12" class="d-flex align-items-center justify-content-center">
              <b-avatar
                :src="
                  selectedImg
                    ? selectedImg
                    : form.imageUrl
                    ? form.imageUrl
                    : require('@/assets/images/app/panels/panel.jpg')
                "
                square
                size="7rem"
                class="img__avatar"
                badge
                id="avatar-equipment"
                badge-variant="light"
              >
                <template #badge>
                  <b-button
                    v-if="selectedImg"
                    class="px-2"
                    size="sm"
                    variant="danger"
                    @click="removeImgSelected"
                  >
                    <i class="simple-icon-trash"></i>
                  </b-button>
                  <b-button class="py-1 px-2" size="sm" variant="primary" @click="pickFile">
                    <i class="simple-icon-cloud-upload"></i>
                  </b-button>
                </template>
              </b-avatar>
            </b-colxx>
            <b-colxx xss="12" class="mt-3">
              <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
                <input
                  ref="inputFile"
                  @change="onFilePicked"
                  type="file"
                  style="display: none"
                  accept="image/png, image/jpg, image/jpeg"
                />
                <b-form-group label="Nombre" class="has-float-label mb-4">
                  <b-form-input
                    type="text"
                    v-model="$v.form.name.$model"
                    :state="!$v.form.name.$error"
                  />
                  <b-form-invalid-feedback v-if="!$v.form.name.required">
                    El nombre de la marca es requerido.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Marca" class="w-100 has-float-label mb-4">
                  <b-form-select
                    v-model="$v.form.trademark.$model"
                    :state="!$v.form.trademark.$error"
                    :options="trademarks"
                    value-field="id"
                    text-field="name"
                    class="w-100"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled>
                        Seleccione...
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback v-if="!$v.form.trademark.required">
                    La marca es requerida.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Modelo" class="has-float-label mb-4">
                  <b-form-input
                    type="text"
                    v-model="$v.form.model.$model"
                    :state="!$v.form.model.$error"
                  />
                  <b-form-invalid-feedback v-if="!$v.form.model.required">
                    El modelo es requerido.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Descripción" class="has-float-label mb-4">
                  <b-form-textarea
                    v-model="form.description"
                    rows="3"
                    no-resize
                  ></b-form-textarea>
                </b-form-group>
                <div class="d-flex justify-content-end">
                  <b-button
                    type="submit"
                    variant="outline-dark"
                    size="md"
                    :disabled="processing"
                    :class="{
                      'btn-multiple-state btn-shadow': true,
                      'show-spinner': processing
                    }"
                  >
                    <span class="spinner d-inline-block">
                      <span class="bounce1"></span>
                      <span class="bounce2"></span>
                      <span class="bounce3"></span>
                    </span>
                    <span class="label"> Guardar </span>
                  </b-button>
                </div>
              </b-form>
            </b-colxx>
          </b-row>
        </b-container>
      </b-modal>
      <b-modal
        id="modal-equipment"
        title="Detalle del equipo"
        modal-class="modal-right"
        @close="closeModalDetails"
        no-close-on-backdrop
        hide-footer
      >
        <b-container fluid>
          <b-row>
            <b-colxx xxs="12" class="d-flex justify-content-center">
              <b-avatar
                :src="equipment.image || '/assets/img/otras/panel.jpg'"
                square
                size="7rem"
                class="img-actions"
              >
              </b-avatar>
            </b-colxx>
            <b-colxx xss="12" class="mt-3">
              <p class="text-muted text-small mb-2">Nombre</p>
              <p class="mb-3">
                {{ equipment.name }}
              </p>
              <p class="text-muted text-small mb-2">Marca</p>
              <p class="mb-3">
                {{ getTrademarkById(equipment.trademark) }}
              </p>
              <p class="text-muted text-small mb-2">Modelo</p>
              <p class="mb-3">
                {{ equipment.model }}
              </p>

              <p class="text-muted text-small mb-2">Descripción</p>
              <p class="mb-3">
                {{ equipment.description }}
              </p>
            </b-colxx>
          </b-row>
        </b-container>
      </b-modal>
      <BaseConfirmModal
        dialogId="delete-equipment"
        title="Eliminar Equipo"
        message="¿Estas seguro de que quieres eliminar este equipo?"
        cancelText="Cancelar"
        proccedText="Aceptar"
        @close="closeDeleteDialog"
        @procced="proccedDelete"
      />
    </div>
  </Layout>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Layout from '@/router/layouts/main'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import adminMixin from '@/mixins/admin'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')
import validateInput from '@/mixins/validateInput'
export default {
  name: 'Equipments',
  components: {
    Layout,
    BaseConfirmModal
  },
  mixins: [validationMixin, validateInput, adminMixin],
  created() {
    this.getEquipments()
    this.getTrademarks()
  },
  data() {
    return {
      selectedToDelete: null,
      processing: false,
      modeEditor: false,
      form: {
        id: null,
        name: '',
        trademark: null,
        model: '',
        description: '',
        image: null,
        imageUrl: null
      },
      selectedImg: null,
      tableEquipments: {
        fields: [
          { key: 'id', label: 'ID', sortable: true, tdClass: 'text-muted', thClass: 'text-white' },
          {
            key: 'name',
            label: 'Nombre',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'trademark',
            label: 'Marca',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'model',
            label: 'Modelo',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'action',
            label: 'Acción',
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          }
        ]
      },
      perPage: 10,
      pageOptions: [10, 20, 30],
      currentPage: 1,
      totalRows: 1,
      filterOn: [],
      filter: null
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      trademark: {
        required
      },
      model: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      equipments: 'admin/equipments',
      equipment: 'admin/equipment',
      trademarks: 'admin/trademarks'
    }),
    rows() {
      return this.equipments.length
    }
  },
  methods: {
    ...mapActions({
      getTrademarks: 'admin/getTrademarks',
      getEquipments: 'admin/getEquipments',
      getEquipment: 'admin/getEquipment',
      createEquipment: 'admin/createEquipment',
      updateEquipment: 'admin/updateEquipment',
      deleteEquipment: 'admin/deleteEquipment'
    }),
    ...mapMutations({ setEquipment: 'admin/setEquipment' }),
    openModal(edit = false, itemId = null) {
      this.modeEditor = edit
      if (itemId) {
        const data = this.equipments.find((item) => item.id === itemId)
        const { id, name, trademark, model, description, image } = data
        this.form = {
          id,
          name,
          trademark,
          model,
          description,
          imageUrl: image,
          image: null
        }
      }
      this.$bvModal.show('modal-equipments')
    },
    closeModal() {
      this.modeEditor = false
      this.$nextTick(() => {
        this.form = {
          id: null,
          name: '',
          trademark: null,
          model: '',
          description: '',
          image: null,
          imageUrl: null
        }
        this.$v.form.$reset()
        this.$bvModal.hide('modal-equipments')
      })
    },
    async openDetails(itemId) {
      const success = await this.getEquipment({
        id: itemId
      })
      if (success) {
        this.$bvModal.show('modal-equipment')
      }
    },
    closeModalDetails() {
      this.setEquipment({})
      this.$bvModal.hide('modal-equipment')
    },
    async formSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { id, ...body } = this.form
        let success
        if (id) {
          success = await this.updateEquipment({
            id,
            body
          })
        } else {
          success = await this.createEquipment({
            body
          })
        }
        this.processing = false
        if (success) {
          this.removeImgSelected()
          this.closeModal()
        }
      }
    },
    removeImgSelected() {
      this.form.image = null
      this.selectedImg = null
    },
    pickFile() {
      this.$refs.inputFile.click()
    },
    onFilePicked(e) {
      const { files } = e.target
      const fr = new FileReader()
      if (files[0] !== undefined) {
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.form.image = files[0]
          this.selectedImg = fr.result
        })
      }
    },
    openDeleteDialog(itemId) {
      this.selectedToDelete = itemId
      this.$bvModal.show('delete-equipment')
    },
    closeDeleteDialog() {
      this.selectedToDelete = null
      this.$bvModal.hide('delete-equipment')
    },
    async proccedDelete() {
      const success = await this.deleteEquipment({
        id: this.selectedToDelete
      })
      if (success) {
        this.closeDeleteDialog()
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<style>
#avatar-equipment > span.b-avatar-badge.badge-light {
  padding: 0 0 0.2rem 0 !important;
  background-color: transparent;
  min-height: auto;
  min-width: auto;
}
</style>
