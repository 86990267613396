<template>
  <b-modal
    id="modal-create-user"
    title="Crear usuario"
    modal-class="modal-right"
    no-body
    @close="closeModalDetails"
    no-close-on-backdrop
    hide-footer
  >
    <b-container>
      <b-row>
        <b-colxx xss="12" class="mt-3">
          <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
            <b-form-group :label="$t('user.first_name')" class="has-float-label mb-4">
              <b-form-input
                type="text"
                v-model="$v.form.firstname.$model"
                :state="!$v.form.firstname.$error"
              />
              <b-form-invalid-feedback v-if="!$v.form.firstname.required">
                {{ $t('validation.first_name') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="$t('user.last_name')" class="has-float-label mb-4">
              <b-form-input
                type="text"
                v-model="$v.form.lastname.$model"
                :state="!$v.form.lastname.$error"
              />
              <b-form-invalid-feedback v-if="!$v.form.lastname.required">
                {{ $t('validation.last_name') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="$t('user.email')" class="has-float-label mb-4">
              <b-form-input
                type="text"
                v-model="$v.form.email.$model"
                :state="!$v.form.email.$error"
              />
              <b-form-invalid-feedback v-if="!$v.form.email.required">
                {{ $t('validation.email') }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-else-if="!$v.form.email.email">
                {{ $t('validation.valid_email') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="$t('user.phone')" class="has-float-label mb-4">
              <b-form-input
                type="text"
                v-model="$v.form.phone.$model"
                :state="!$v.form.phone.$error"
              />
              <b-form-invalid-feedback v-if="!$v.form.phone.required">
                {{ $t('validation.phone') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="$t('user.address')" class="has-float-label mb-4">
              <b-form-textarea
                v-model="$v.form.address.$model"
                :state="!$v.form.address.$error"
              />
              <b-form-invalid-feedback v-if="!$v.form.address.required">
                {{ $t('validation.address') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="$t('user.password')" class="has-float-label mb-4">
              <b-form-input
                type="password"
                v-model="$v.form.password.$model"
                :state="!$v.form.password.$error"
              />
              <b-form-invalid-feedback v-if="!$v.form.password.required">
                {{ $t('validation.password') }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-else-if="!$v.form.password.minLength || !$v.form.password.maxLength"
                >{{ $t('validation.max_length') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Confirmación de contraseña" class="has-float-label mb-4">
              <b-form-input
                type="password"
                v-model="$v.form.repeatPassword.$model"
                :state="!$v.form.repeatPassword.$error"
              />
              <b-form-invalid-feedback v-if="!$v.form.repeatPassword.required">
                Por favor confirma tu contraseña
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.form.repeatPassword.sameAsPassword">
                Las contraseñas no coinciden
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-else-if="
                  !$v.form.repeatPassword.minLength || !$v.form.repeatPassword.maxLength
                "
                >{{ $t('validation.max_length') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <div class="d-flex justify-content-end align-items-center">
              <b-button
                type="submit"
                variant="outline-dark"
                size="md"
                :disabled="processing"
                :class="{
                  'btn-multiple-state btn-shadow': true,
                  'show-spinner': processing,
                  'show-success': !processing === false
                }"
              >
                <span class="spinner d-inline-block">
                  <span class="bounce1"></span>
                  <span class="bounce2"></span>
                  <span class="bounce3"></span>
                </span>
                <span class="label">Crear</span>
              </b-button>
            </div>
          </b-form>
        </b-colxx>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
const { required, maxLength, minLength, email, sameAs } = require('vuelidate/lib/validators')
export default {
  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        address: '',
        password: '',
        repeatPassword: ''
      }
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      firstname: {
        required,
        minLength: minLength(1)
      },
      lastname: {
        required,
        minLength: minLength(1)
      },
      phone: {
        required,
        minLength: minLength(1)
      },
      address: {
        required,
        minLength: minLength(1)
      },
      password: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(4)
      },
      email: {
        required,
        email,
        minLength: minLength(4)
      },
      repeatPassword: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(4),
        sameAsPassword: sameAs('password')
      }
    }
  },
  computed: {
    ...mapGetters({ processing: 'user/processing' })
  },
  methods: {
    ...mapActions({ createUser: 'admin/createUser' }),
    closeModalDetails() {
      this.form = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        address: '',
        password: '',
        repeatPassword: ''
      }
      this.$v.form.$reset()
      this.$bvModal.hide('modal-create-user')
    },
    async formSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        const success = await this.createUser({
          email: this.form.email,
          password: this.form.password,
          phone: this.form.phone,
          address: this.form.address,
          last_name: this.form.lastname,
          first_name: this.form.firstname
        })
        if (success) {
          this.closeModalDetails()
          this.$emit('saved')
        }
      }
    }
  }
}
</script>

<style></style>
