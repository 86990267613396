<template>
  <b-card class="mb-4" :title="$t('notifications.create')">
    <b-container fluid class="mt-3">
      <b-form @submit.prevent="sendNotification" class="av-tooltip tooltip-label-bottom">
        <b-row>
          <b-colxx xxs="12" sm="12" lg="6" md="6">
            <b-form-group label="Título" class="has-float-label mb-4">
              <b-form-textarea
                type="text"
                v-model="$v.form.title.$model"
                :state="!$v.form.title.$error"
              />
              <b-form-invalid-feedback v-if="!$v.form.title.required">
                El Título es requerido.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-colxx>
          <b-colxx xxs="12" sm="12" lg="6" md="6">
            <b-form-group label="Mensaje" class="has-float-label mb-4">
              <b-form-textarea
                v-model="$v.form.content.$model"
                :state="!$v.form.content.$error"
              />
              <b-form-invalid-feedback v-if="!$v.form.content.required">
                El Mensaje es requerido.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-colxx>

          <b-colxx xxs="12" sm="12" lg="6" md="6">
            <label for="" class="w-100">
              Deseo enviar a:
              <b-form-group class="w-100 has-float-label mb-4">
                <b-form-select
                  v-model="$v.form.type_notification.$model"
                  :state="!$v.form.type_notification.$error"
                  :options="typesNotifications"
                  value-field="id"
                  text-field="name"
                  class="w-100"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      Selecciona una opción
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback v-if="!$v.form.type_notification.required">
                  Debe seleccionar un destinatario
                </b-form-invalid-feedback>
              </b-form-group>
            </label>
          </b-colxx>

          <!-- Tags -->
          <b-colxx
            lg="6"
            md="6"
            sm="12"
            xxs="12"
            v-if="form.type_notification == typesNotificationMap.tag"
          >
            <label for="" class="w-100">
              {{ $t('menu.tag') }}
              <b-form-group class="w-100 has-float-label mb-4">
                <b-form-select
                  class="w-100"
                  v-model="form.tag_id"
                  :options="tags"
                  value-field="id"
                  text-field="name"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      Selecciona una Etiqueta
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </label>
          </b-colxx>
          <!-- Projects -->
          <b-colxx
            lg="6"
            md="6"
            sm="12"
            xxs="12"
            v-if="form.type_notification == typesNotificationMap.project"
          >
            <label for="" class="w-100">
              {{ $t('general.project') }}
              <b-form-group class="w-100 has-float-label mb-4">
                <b-form-select
                  class="w-100"
                  v-model="form.project_id"
                  :options="projects"
                  value-field="id"
                  text-field="name"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      Selecciona un Proyecto
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </label>
          </b-colxx>

          <b-colxx
            lg="6"
            md="6"
            sm="12"
            xxs="12"
            v-if="form.type_notification == typesNotificationMap.users"
          >
            <label for="" class="w-100">
              {{ $t('menu.users') }}

              <b-form-group class="w-100 has-float-label mb-4" v-if="currentUser">
                <v-select
                  class="pb-1 w-100"
                  :reduce="(user) => user.id"
                  multiple
                  placeholder="Seleccionar usuarios"
                  :options="
                    users.filter((user) => !user.is_staff && user.id !== currentUser.id)
                  "
                  v-model="form.users"
                  label="fullname"
                >
                </v-select>
              </b-form-group>
            </label>
          </b-colxx>

          <b-colxx lg="12" md="12" sm="12" xxs="12">
            <div class="d-flex justify-content-end">
              <b-button
                type="submit"
                variant="outline-dark"
                size="md"
                :disabled="processing"
                :class="{
                  'btn-multiple-state btn-shadow': true,
                  'show-spinner': processing
                }"
              >
                <span class="spinner d-inline-block">
                  <span class="bounce1"></span>
                  <span class="bounce2"></span>
                  <span class="bounce3"></span>
                </span>
                <span class="label">
                  {{ $t('survey.save') }}
                </span>
              </b-button>
            </div>
          </b-colxx>
        </b-row>
      </b-form>
    </b-container>
  </b-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  mixins: [validationMixin],
  components: {
    'v-select': vSelect
  },
  data() {
    return {
      typesNotificationMap: {
        tag: 1,
        project: 2,
        users: 3
      },
      processing: false,
      form: {
        title: '',
        content: '',
        tag_id: null,
        users: [],
        project_id: null,
        type_notification: null
      }
    }
  },
  async created() {
    this.getTypeNotifications()
    this.getTags()
    this.getUsers()
    this.getProjects()
  },
  computed: {
    ...mapGetters({
      tags: 'tag/tags',
      projects: 'project/projects',
      users: 'project/users',
      currentUser: 'user/currentUser',
      typesNotifications: 'notifications/typesNotifications'
    })
  },
  methods: {
    ...mapActions({
      getTags: 'tag/getTags',
      getTypeNotifications: 'notifications/getTypeNotifications',
      getUsers: 'project/getUsers',
      getProjects: 'project/getProjects',
      sendNewNotification: 'notifications/sendNewNotification'
    }),
    async sendNotification() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.form.users.length && !this.form.tag_id && !this.form.project_id) {
        return this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Debe ingresar a quien va dirigida la notificación.',
          showConfirmButton: true
        })
      }
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { title, content, type_notification } = this.form
        await this.sendNewNotification({
          title,
          content,
          type_notification,
          tag_id:
            this.form.type_notification == this.typesNotificationMap.tag
              ? this.form.tag_id
              : undefined,
          project_id:
            this.form.type_notification == this.typesNotificationMap.project
              ? this.form.project_id
              : undefined,
          users:
            this.form.type_notification == this.typesNotificationMap.users
              ? this.form.users
              : undefined
        })
        this.processing = false
        this.resetForm()
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    },
    resetForm() {
      this.form = {
        title: '',
        content: '',
        type_notification: null,
        users: [],
        tag_id: null,
        project_id: null
      }
    }
  },
  validations: {
    form: {
      type_notification: {
        required
      },
      title: {
        required
      },
      content: {
        required
      }
    }
  }
}
</script>

<style></style>
