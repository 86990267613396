<template>
  <Layout>
    <div class="p-4">
      <b-row>
        <b-row align-h="between">
          <div class="d-flex justify-content-between">
            <h1>{{ $t('menu.tags') }}</h1>
            <div class="mr-3">
              <b-button @click="openModal()" variant="outline-dark">
                {{ $t('survey.new') }}
              </b-button>
            </div>
          </div>
          <div class="separator mb-3"></div>
        </b-row>
        <b-colxx xxs="12">
          <b-card no-body>
            <b-card-text>
              <b-table
                :fields="tagsTable.fields"
                :items="tags"
                responsive
                hover
                show-empty
                :empty-text="$t('validation.empty_tags')"
              >
                <template #cell(action)="data">
                  <div class="text-center">
                    <b-button
                      pill
                      class="mx-1"
                      size="sm"
                      variant="info"
                      @click="openDetails(data.item.id)"
                    >
                      <i class="simple-icon-eye"></i>
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      class="mx-1"
                      variant="success"
                      @click="openModal(true, data.item.id)"
                    >
                      <i class="simple-icon-pencil"></i>
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      class="mx-1"
                      variant="danger"
                      @click="openDeleteDialog(data.item.id)"
                    >
                      <i class="simple-icon-trash"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-card-text>
          </b-card>
        </b-colxx>
      </b-row>
      <b-modal
        id="modal-tag"
        :title="$t('pages.tags.details')"
        modal-class="modal-right"
        no-body
        @close="closeModalDetails"
        no-close-on-backdrop
        hide-footer
      >
        <b-container fluid>
          <b-row>
            <b-colxx xss="12" class="mt-3">
              <p class="text-muted text-small mb-2">
                {{ $t('forms.name') }}
              </p>
              <p class="mb-3">
                {{ tag.name }}
              </p>
            </b-colxx>
          </b-row>
          <!-- Aqui van los usuarios y proyectos -->
          <tags-projects />
          <tags-users />
        </b-container>
      </b-modal>
      <b-modal
        id="modal-tags"
        :title="
          modeEditor
            ? `${$t('survey.edit')} ${$t('menu.tag')}`
            : `${$t('forms.add')} ${$t('menu.tag')}`
        "
        modal-class="modal-right"
        @close="closeModal"
        no-close-on-backdrop
        hide-footer
      >
        <b-container fluid>
          <b-row>
            <b-colxx xss="12" class="mt-3">
              <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
                <b-form-group label="Nombre" class="has-float-label mb-4">
                  <b-form-input
                    type="text"
                    v-model="$v.form.name.$model"
                    :state="!$v.form.name.$error"
                  />
                  <b-form-invalid-feedback v-if="!$v.form.name.required">
                    {{ $t('validation.name_required') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <div class="d-flex justify-content-end">
                  <b-button
                    type="submit"
                    variant="outline-dark"
                    size="md"
                    :disabled="processing"
                    :class="{
                      'btn-multiple-state btn-shadow': true,
                      'show-spinner': processing
                    }"
                  >
                    <span class="spinner d-inline-block">
                      <span class="bounce1"></span>
                      <span class="bounce2"></span>
                      <span class="bounce3"></span>
                    </span>
                    <span class="label">
                      {{ $t('survey.save') }}
                    </span>
                  </b-button>
                </div>
              </b-form>
            </b-colxx>
          </b-row>
        </b-container>
      </b-modal>
      <BaseConfirmModal
        dialogId="delete-tag"
        :title="`${$t('pages.delete')} ${$t('menu.tag')}`"
        :message="`¿ ${$t('general.question_delete')} ${$t('menu.tag')} ?`"
        :cancelText="$t('pages.cancel')"
        :proccedText="$t('button.accept')"
        @close="closeDeleteDialog"
        @procced="proccedDelete"
      />
    </div>
  </Layout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import adminMixin from '@/mixins/admin'
import { validationMixin } from 'vuelidate'
import TagsUsers from './tagsUsers.vue'
import TagsProjects from './tagsProjects.vue'
const { required } = require('vuelidate/lib/validators')
import Layout from '@/router/layouts/main'

export default {
  name: 'tags',
  components: {
    BaseConfirmModal,
    TagsUsers,
    TagsProjects,
    Layout
  },
  mixins: [validationMixin, adminMixin],
  data() {
    return {
      selectedToDelete: null,
      processing: false,
      modeEditor: false,
      form: {
        id: null,
        name: ''
      },
      tagsTable: {
        fields: [
          {
            key: 'name',
            label: this.$t('forms.name'),
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'action',
            label: this.$t('dropdowns.action'),
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          }
        ]
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({ tag: 'tag/tag', tags: 'tag/tags' })
  },
  created() {
    this.getTags()
    this.getUsers()
    this.getProjects()
  },
  methods: {
    ...mapActions({
      getTags: 'tag/getTags',
      getTag: 'tag/getTag',
      createTag: 'tag/createTag',
      updateTag: 'tag/updateTag',
      deleteTag: 'tag/deleteTag',
      getUsers: 'project/getUsers',
      getProjects: 'project/getProjects'
    }),
    ...mapMutations({ setTag: 'tag/setTag' }),
    openModal(edit = false, itemId = null) {
      this.modeEditor = edit
      if (itemId) {
        const data = this.tags.find((item) => item.id === itemId)
        const { id, name } = data
        this.form = {
          id,
          name
        }
      }
      this.$bvModal.show('modal-tags')
    },
    closeModal() {
      this.modeEditor = false
      this.$nextTick(() => {
        this.form = {
          id: null,
          name: ''
        }
        this.$v.form.$reset()
        this.$bvModal.hide('modal-tags')
      })
    },
    async openDetails(itemId) {
      const success = await this.getTag({
        id: itemId
      })
      if (success) {
        this.$bvModal.show('modal-tag')
      }
    },
    closeModalDetails() {
      this.setTag({})
      this.$bvModal.hide('modal-tag')
    },
    async formSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { id, ...rest } = this.form
        let success
        if (id) {
          success = await this.updateTag({
            id,
            body: {
              ...rest
            }
          })
        } else {
          success = await this.createTag({
            body: {
              ...rest
            }
          })
        }
        this.processing = false
        if (success) {
          this.closeModal()
        }
      }
    },
    openDeleteDialog(itemId) {
      this.selectedToDelete = itemId
      this.$bvModal.show('delete-tag')
    },
    closeDeleteDialog() {
      this.selectedToDelete = null
      this.$bvModal.hide('delete-tag')
    },
    async proccedDelete() {
      const success = await this.deleteTag({
        id: this.selectedToDelete
      })
      if (success) {
        this.closeDeleteDialog()
      }
    }
  }
}
</script>

<style>
#modal-tag.modal-right .modal-dialog {
  max-width: 60vw;
}
</style>
