<template>
  <b-modal :id="dialogId" :title="title" @close="$emit('close')" no-close-on-backdrop>
    <p class="text-muted mb-2" v-if="message">
      {{ message }}
    </p>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          size="sm"
          class="float-right me-2"
          @click="$emit('procced')"
        >
          {{ proccedText }}
        </b-button>
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="$emit('close')"
        >
          {{ cancelText }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'BaseConfirmModal',
  props: {
    dialogId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: false
    },
    cancelText: {
      type: String,
      required: true
    },
    proccedText: {
      type: String,
      required: true
    }
  }
}
</script>

<style></style>
