<template>
  <Layout>
    <div class="p-4">
      <b-row class="mt-4">
        <div class="d-flex justify-content-between">
          <div class="ml-2">
            <h1>{{ $t('dashboards.users') }}</h1>
          </div>
          <div class="mr-3">
            <b-button @click="openCreate()" variant="outline-dark"> Crear Usuario </b-button>
          </div>
        </div>
        <div class="separator mb-3"></div>
        <b-colxx xxs="12">
          <b-card no-body>
            <b-card-text>
              <b-row class="d-flex justify-content-between flex-wrap my-3">
                <b-col cols="4">
                  <b-form-group
                    label-align-sm="left"
                    label-for="filterInput"
                    class="mb-0 ml-3 p-2"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Buscar usuarios"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group
                    label-cols="5"
                    label="Mostrar"
                    label-align="left"
                    label-size="default"
                    label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-2"
                  >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      inline
                      :options="pageOptions"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                id="table-users"
                :fields="usersTable.fields"
                :per-page="perPage"
                :current-page="currentPage"
                :items="users"
                responsive
                hover
                show-empty
                empty-text="No hay usuarios registrados."
                empty-filtered-text="No hay usuarios para filtrar."
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="ml-3"
              >
                <template #cell(first_name)="{ item }">
                  <div class="mt-1">
                    {{ item.first_name ? item.first_name : 'Administrador' }}
                  </div>
                </template>

                <template #cell(last_name)="{ item }">
                  <div class="mt-1">
                    {{ item.last_name ? item.last_name : 'Administrador' }}
                  </div>
                </template>

                <template #cell(is_staff)="{ item }">
                  <div class="mt-1">
                    <b-badge :variant="item.is_staff ? 'warning' : 'primary'">
                      <span class="" style="font-size: 14px">
                        {{ item.is_staff ? 'Administrador' : 'Usuario' }}
                      </span></b-badge
                    >
                  </div>
                </template>
                <template #cell(action)="data">
                  <div class="">
                    <b-button
                      pill
                      size="sm"
                      class="mx-1"
                      variant="info"
                      v-b-tooltip.hover
                      title="Ver usuario"
                      @click="openDetails(data.item)"
                    >
                      <i class="simple-icon-eye"></i>
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      class="mx-1"
                      :variant="!data.item.is_staff ? 'warning' : 'danger'"
                      v-b-tooltip.hover
                      :title="
                        data.item.is_staff
                          ? 'Revocar Administración'
                          : 'Asignar Administración'
                      "
                      @click="openAdminDialog(data.item.id)"
                    >
                      <i class="simple-icon-user"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
              <b-row class="d-flex justify-content-center align-items-center flex-wrap pt-0">
                <div>
                  <b-pagination
                    pills
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="table-users"
                    class="ml-2"
                  ></b-pagination>
                </div>
              </b-row>
            </b-card-text>
          </b-card>
        </b-colxx>
      </b-row>
      <b-modal
        id="modal-user"
        title="Detalle del Usuario"
        modal-class="modal-right"
        no-body
        @close="closeModalDetails"
        no-close-on-backdrop
        hide-footer
      >
        <b-container fluid v-if="userDetail">
          <b-row>
            <b-colxx xss="12" class="mt-3">
              <p class="text-muted text-small mb-2">Nombre</p>
              <p class="mb-3">
                {{ getUserById(userDetail.id).first_name }}
                {{ getUserById(userDetail.id).last_name }}
              </p>
              <p class="text-muted text-small mb-2">Email</p>
              <p class="mb-3">
                {{ getUserById(userDetail.id).email }}
              </p>
              <p v-if="getUserById(userDetail.id).phone" class="text-muted text-small mb-2">
                Telefono
              </p>
              <p v-if="getUserById(userDetail.id).phone" class="mb-3">
                {{ getUserById(userDetail.customuser).phone }}
              </p>
              <p
                v-if="getUserById(userDetail.customuser).address"
                class="text-muted text-small mb-2"
              >
                Dirección
              </p>
              <p v-if="getUserById(userDetail.id).address" class="mb-3">
                {{ getUserById(userDetail.id).address }}
              </p>
            </b-colxx>
          </b-row>
        </b-container>
      </b-modal>
      <BaseConfirmModal
        dialogId="admin-project-user"
        :title="`${!permissionsCurrentUser ? 'Asignar' : 'Revocar'} Administración de Usuario`"
        :message="`¿Estas seguro de que quieres ${
          !permissionsCurrentUser ? 'Asignar' : 'Revocar'
        } la administración este usuario?`"
        cancelText="Cancelar"
        proccedText="Aceptar"
        @close="closeAdminDialog"
        @procced="assignAdminUser"
      />
      <create-admin @saved="this.getUsers" />
    </div>
  </Layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import createAdmin from './createAdmin'
import adminMixin from '@/mixins/admin'
import Layout from '@/router/layouts/main'
export default {
  name: 'users',
  components: {
    Layout,
    BaseConfirmModal,
    createAdmin
  },
  mixins: [adminMixin],
  created() {
    this.getUsers()
  },
  data() {
    return {
      selectedToAdmin: null,
      permissionsCurrentUser: null,
      processing: false,
      perPage: 10,
      pageOptions: [10, 20, 30],
      currentPage: 1,
      totalRows: 1,
      filterOn: [],
      filter: null,
      usersTable: {
        fields: [
          {
            key: 'first_name',
            label: 'Nombre',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'last_name',
            label: 'Apellido',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'email',
            label: 'Correo Electrónico',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'is_staff',
            label: 'Rol',
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'action',
            label: 'Acción',
            sortable: false,
            tdClass: 'text-muted mx-auto',
            thClass: 'text-center text-white'
          }
        ]
      },
      userDetail: null,
      selectedImg: null
    }
  },
  computed: {
    ...mapGetters({ users: 'project/users', currentUser: 'user/currentUser' }),
    rows() {
      return this.users.length
    }
  },
  methods: {
    ...mapActions({
      getUsers: 'project/getUsers',
      postAssignAdminUser: 'project/postAssignAdminUser'
    }),
    async openCreate() {
      this.$bvModal.show('modal-create-user')
    },
    async openDetails(user) {
      this.userDetail = user
      this.$bvModal.show('modal-user')
    },
    closeModalDetails() {
      this.userDetail = null
      this.$bvModal.hide('modal-user')
    },

    openAdminDialog(itemId) {
      this.selectedToAdmin = itemId
      const user = this.users.find((user) => user.id == this.selectedToAdmin)
      this.permissionsCurrentUser = user.is_staff
      this.$bvModal.show('admin-project-user')
    },
    closeAdminDialog() {
      this.selectedToAdmin = null
      this.$bvModal.hide('admin-project-user')
    },
    async assignAdminUser() {
      await this.postAssignAdminUser({
        user_id: this.selectedToAdmin,
        boolean: !this.permissionsCurrentUser
      })
      this.$bvModal.hide('admin-project-user')
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<style></style>
