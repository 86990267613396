<template>
  <div>
    <b-row>
      <div class="d-flex justify-content-end mb-3">
        <b-button @click="openModal()" variant="outline-dark"> Nuevo </b-button>
      </div>
      <b-colxx xxs="12">
        <b-card no-body>
          <b-card-title class="mb-0 py-2 px-3"> Inversores </b-card-title>
          <b-card-text>
            <b-table
              :fields="investorsTable.fields"
              :items="investors"
              responsive
              hover
              show-empty
              empty-text="No hay inversores registrados."
            >
              <template #cell(equipment)="data">
                {{ getEquipmentById(data.value) }}
              </template>
              <template #cell(action)="data">
                <div class="text-center">
                  <b-button
                    pill
                    size="sm"
                    class="mx-1"
                    variant="info"
                    @click="openDetails(data.item.id)"
                  >
                    <i class="simple-icon-eye"></i>
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    class="mx-1"
                    variant="success"
                    @click="openModal(true, data.item.id)"
                  >
                    <i class="simple-icon-pencil"></i>
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    class="mx-1"
                    variant="danger"
                    @click="openDeleteDialog(data.item.id)"
                  >
                    <i class="simple-icon-trash"></i>
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
      </b-colxx>
    </b-row>
    <b-modal
      id="modal-investor"
      title="Detalle del Inversor"
      modal-class="modal-right"
      no-body
      @close="closeModalDetails"
      no-close-on-backdrop
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-colxx xss="12" class="mt-3">
            <p class="text-muted text-small mb-2">Equipo</p>
            <p class="mb-3">
              {{ getEquipmentById(investor.equipment) }}
            </p>
            <p class="text-muted text-small mb-2">Central Eléctrica</p>
            <p class="mb-3">
              {{ getPowerPlantById(investor.plant) }}
            </p>
            <p class="text-muted text-small mb-2">Código de Inversor</p>
            <p class="mb-3">
              {{ investor.station_code }}
            </p>
          </b-colxx>
        </b-row>
        <Mppts />
      </b-container>
    </b-modal>
    <b-modal
      id="modal-investors"
      :title="modeEditor ? 'Editar Inversor' : 'Agregar Inversor'"
      modal-class="modal-right"
      @close="closeModal"
      no-close-on-backdrop
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-colxx xss="12" class="mt-3">
            <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
              <b-form-group label="Equipo" class="w-100 has-float-label mb-4">
                <b-form-select
                  v-model="$v.form.equipment.$model"
                  :state="!$v.form.equipment.$error"
                  :options="equipments"
                  value-field="id"
                  text-field="name"
                  class="w-100"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      Seleccione un equipo...
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback v-if="!$v.form.equipment.required">
                  El equipo es requerido.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Nombre del inversor" class="has-float-label mb-4">
                <b-form-input
                  type="text"
                  v-model="$v.form.station.$model"
                  :state="!$v.form.station.$error"
                />
                <b-form-invalid-feedback v-if="!$v.form.station.required">
                  El nombre del inversor es requerido.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Código del Inversor (API)" class="has-float-label mb-4">
                <b-form-input
                  type="text"
                  v-model="$v.form.station_code.$model"
                  :state="!$v.form.station_code.$error"
                />
                <b-form-invalid-feedback v-if="!$v.form.station_code.required">
                  El Código del Inversor es requerido.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox v-model="form.multi_inversor">
                  <span class="ms-2"> Multi inversor </span>
                </b-form-checkbox>
              </b-form-group>
              <div class="d-flex justify-content-end">
                <b-button
                  type="submit"
                  variant="outline-dark"
                  size="md"
                  :disabled="processing"
                  :class="{
                    'btn-multiple-state btn-shadow': true,
                    'show-spinner': processing
                  }"
                >
                  <span class="spinner d-inline-block">
                    <span class="bounce1"></span>
                    <span class="bounce2"></span>
                    <span class="bounce3"></span>
                  </span>
                  <span class="label"> Guardar </span>
                </b-button>
              </div>
            </b-form>
          </b-colxx>
        </b-row>
      </b-container>
    </b-modal>
    <BaseConfirmModal
      dialogId="delete-investor"
      title="Eliminar Inversor"
      message="¿Estas seguro de que quieres eliminar este inversor?"
      cancelText="Cancelar"
      proccedText="Aceptar"
      @close="closeDeleteDialog"
      @procced="proccedDelete"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import adminMixin from '@/mixins/admin'
import { validationMixin } from 'vuelidate'
import Mppts from './sections/mppts.vue'
const { required } = require('vuelidate/lib/validators')

export default {
  name: 'Investors',
  components: {
    BaseConfirmModal,
    Mppts
  },
  mixins: [validationMixin, adminMixin],
  data() {
    return {
      selectedToDelete: null,
      processing: false,
      modeEditor: false,
      form: {
        id: null,
        plant: null,
        equipment: null,
        multi_inversor: false,
        station_code: null,
        station: null
      },
      investorsTable: {
        fields: [
          {
            key: 'station',
            label: 'Nombre del Inversor',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'equipment',
            label: 'Equipo',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'action',
            label: 'Acción',
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-white text-center'
          }
        ]
      },
      selectedImg: null
    }
  },
  validations: {
    form: {
      equipment: {
        required
      },
      station: {
        required
      },
      station_code: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      project: 'project/project',
      investor: 'project/investor',
      investors: 'project/investors',
      equipments: 'admin/equipments',
      powerPlant: 'project/powerPlant'
    })
  },
  methods: {
    ...mapActions({
      getInvestors: 'project/getInvestors',
      getInvestor: 'project/getInvestor',
      createInvestor: 'project/createInvestor',
      updateInvestor: 'project/updateInvestor',
      deleteInvestor: 'project/deleteInvestor'
    }),
    ...mapMutations({ setInvestor: 'project/setInvestor' }),
    openModal(edit = false, itemId = null) {
      this.modeEditor = edit
      if (itemId) {
        const data = this.investors.find((item) => item.id === itemId)
        const { id, equipment, plant, multi_inversor, station_code, station } = data
        this.form = {
          id,
          equipment,
          plant,
          station_code,
          multi_inversor,
          station
        }
      }
      this.$bvModal.show('modal-investors')
    },
    closeModal() {
      this.modeEditor = false
      this.$nextTick(() => {
        this.form = {
          id: null,
          equipment: null,
          plant: null,
          station_code: null,
          multi_inversor: false,
          station: null
        }
        this.$v.form.$reset()
        this.$bvModal.hide('modal-investors')
      })
    },
    async openDetails(itemId) {
      const success = await this.getInvestor({
        id: itemId
      })
      if (success) {
        this.$bvModal.show('modal-investor')
      }
    },
    closeModalDetails() {
      this.setInvestor({})
      this.$bvModal.hide('modal-investor')
    },
    async formSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { id, ...rest } = this.form
        let success
        if (id) {
          success = await this.updateInvestor({
            id,
            body: {
              ...rest
            }
          })
        } else {
          success = await this.createInvestor({
            body: {
              ...rest,
              plant: this.powerPlant.id
            }
          })
        }
        this.processing = false
        if (success) {
          this.closeModal()
        }
      }
    },
    openDeleteDialog(itemId) {
      this.selectedToDelete = itemId
      this.$bvModal.show('delete-investor')
    },
    closeDeleteDialog() {
      this.selectedToDelete = null
      this.$bvModal.hide('delete-investor')
    },
    async proccedDelete() {
      const success = await this.deleteInvestor({
        id: this.selectedToDelete,
        plant: this.powerPlant.id
      })
      if (success) {
        this.closeDeleteDialog()
      }
    }
  }
}
</script>

<style>
#modal-investor.modal-right .modal-dialog {
  max-width: 71vw;
}
</style>
