<template>
  <Layout>
    <div class="p-4">
      <b-row>
        <b-colxx xxs="12">
          <h1>{{ $t('menu.notifications') }}</h1>
          <div class="separator mb-3"></div>
        </b-colxx>
      </b-row>
      <b-row align-v="center">
        <b-colxx xxs="12">
          <create-notifications></create-notifications>
        </b-colxx>
        <b-colxx xxs="12">
          <b-card :title="$t('dashboards.notifications_list')">
            <b-card-text>
              <b-table
                :fields="notificationsTable.fields"
                :items="notifications"
                responsive
                hover
                show-empty
                :empty-text="$t('validation.empty_notifications')"
              >
                <template #cell(action)="data">
                  <div class="text-center">
                    <b-button
                      pill
                      size="sm"
                      variant="primary"
                      @click="openDetails(data.item.id)"
                    >
                      <i class="simple-icon-eye"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-card-text>
          </b-card>
        </b-colxx>
      </b-row>
      <b-modal
        id="modal-notification"
        :title="$t('menu.notification')"
        modal-class="modal-right"
        no-body
        @close="closeModalDetails"
        no-close-on-backdrop
        hide-footer
      >
        <b-container fluid v-if="notificationShow">
          <b-colxx xss="12" class="mt-3">
            <p class="text-muted text-small mb-2">
              {{ $t('chat.title') }}
            </p>
            <p class="mb-3">
              {{ notificationShow.title }}
            </p>
          </b-colxx>
          <b-colxx xss="12" class="mt-3">
            <p class="text-muted text-small mb-2">
              {{ $t('todo.type') }}
            </p>
            <p class="mb-3">
              {{ notificationShow.type_notification.name }}
            </p>
          </b-colxx>
          <b-colxx xss="12" class="mt-3">
            <p class="text-muted text-small mb-2">
              {{ $t('forms.date') }}
            </p>
            <p class="mb-3">
              {{ $moment(notificationShow.created_at).format('DD/MM/YYYY') }}
            </p>
          </b-colxx>
          <b-colxx xss="12" class="mt-3">
            <p class="text-muted text-small mb-2">
              {{ $t('chat.message') }}
            </p>
            <p class="mb-3">
              {{ notificationShow.content }}
            </p>
          </b-colxx>
        </b-container>
      </b-modal>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/main'
import { mapGetters, mapActions } from 'vuex'
import CreateNotifications from './CreateNotifications.vue'

export default {
  components: {
    Layout,
    CreateNotifications
  },
  data() {
    return {
      notificationsTable: {
        fields: [
          {
            key: 'title',
            label: this.$t('todo.title'),
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'type_notification.name',
            label: this.$t('todo.type'),
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'action',
            label: this.$t('dropdowns.action'),
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          }
        ]
      },
      notificationShow: null
    }
  },
  created() {
    this.getNotifications()
  },
  methods: {
    ...mapActions({ getNotifications: 'notifications/getNotifications' }),
    async openDetails(itemId) {
      this.notificationShow = this.notifications.find(
        (notification) => notification.id == itemId
      )
      if (this.notificationShow) {
        this.$bvModal.show('modal-notification')
      }
    },
    closeModalDetails() {
      this.notificationShow = null
      this.$bvModal.hide('modal-notification')
    }
  },
  computed: {
    ...mapGetters({ notifications: 'notifications/notifications' })
  }
}
</script>

<style></style>
