<template>
  <Layout>
    <div class="p-4">
      <b-row>
        <b-colxx xxs="12">
          <h1>{{ $t('menuitems.items.config') }}</h1>
          <div class="separator mb-3"></div>
        </b-colxx>
      </b-row>
      <b-row align-v="center">
        <b-colxx xxs="12">
          <b-card class="mb-4 w-75">
            <b-container fluid class="mt-3">
              <b-form
                @submit.prevent="updateConfiguration"
                class="av-tooltip tooltip-label-bottom"
              >
                <b-row align-content="center">
                  <b-colxx xxs="12" sm="12" lg="12" md="12">
                    <b-form-group class="has-float-label mb-4">
                      <b-form-checkbox
                        id="checkbox-2"
                        v-model="form.time_zone"
                        name="checkbox-2"
                      >
                        <span class="mx-2 mt-2"> Habilitar Zona horaria </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-colxx>
                  <b-colxx xxs="12" sm="12" lg="8" md="8">
                    <b-form-group
                      label="Variable de calculo de impacto ambiental"
                      class="has-float-label mb-4 mt-2"
                    >
                      <b-form-input type="number" v-model="form.environnement_factor" />
                    </b-form-group>
                  </b-colxx>
                  <b-colxx lg="12" md="12" sm="12" xxs="12">
                    <div class="d-flex justify-content-end mt-2">
                      <b-button
                        type="submit"
                        variant="outline-dark"
                        size="md"
                        :disabled="processing"
                        :class="{
                          'btn-multiple-state btn-shadow': true,
                          'show-spinner': processing
                        }"
                      >
                        <span class="spinner d-inline-block">
                          <span class="bounce1"></span>
                          <span class="bounce2"></span>
                          <span class="bounce3"></span>
                        </span>
                        <span class="label">
                          {{ $t('survey.edit') }}
                        </span>
                      </b-button>
                    </div>
                  </b-colxx>
                </b-row>
              </b-form>
            </b-container>
          </b-card>
        </b-colxx>
      </b-row>
    </div>
  </Layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Layout from '@/router/layouts/main'

export default {
  components: {
    Layout
  },
  data() {
    return {
      processing: false,
      form: {
        id: null,
        environnement_factor: null,
        time_zone: false
      }
    }
  },
  async created() {
    this.getConfig()
  },
  computed: {
    ...mapGetters({
      configurations: 'tools/configurations'
    })
  },
  methods: {
    ...mapActions({
      getConfig: 'tools/getConfig',
      sendUpdateConfiguration: 'tools/updateConfiguration'
    }),
    async updateConfiguration() {
      this.processing = true
      const { id, environnement_factor, time_zone } = this.form
      await this.sendUpdateConfiguration({
        id,
        name: 'Configuration',
        environnement_factor,
        time_zone
      })
      this.processing = false
    }
  },
  watch: {
    configurations(newValue) {
      if (newValue.length) {
        const configuration = newValue[0]
        this.form.id = configuration.id
        this.form.time_zone = configuration.time_zone
        this.form.environnement_factor = configuration.environnement_factor
      }
    }
  }
}
</script>

<style></style>
