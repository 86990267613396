<template>
  <div>
    <b-row>
      <div class="d-flex justify-content-end mb-3">
        <b-button @click="openModal()" variant="outline-dark"> Nuevo </b-button>
      </div>

      <b-colxx xxs="12">
        <b-card no-body>
          <b-card-title class="mb-0 py-2 px-3"> Centrales Eléctricas </b-card-title>
          <b-card-text>
            <b-table
              :fields="powerPlantsTable.fields"
              :items="powerPlants"
              responsive
              hover
              show-empty
              empty-text="No hay centrales eléctricas registradas."
            >
              <template #cell(commune)="data">
                <span v-if="data.item.commune">
                  {{ data.item.commune.complete_address }}
                </span>
                <span v-else> --- </span>
              </template>
              <template #cell(action)="data">
                <div class="text-center">
                  <b-button
                    class="mx-1"
                    pill
                    size="sm"
                    variant="info"
                    @click="openDetails(data.item.id)"
                  >
                    <i class="simple-icon-eye"></i>
                  </b-button>
                  <b-button
                    pill
                    class="mx-1"
                    size="sm"
                    variant="success"
                    @click="openModal(true, data.item.id)"
                  >
                    <i class="simple-icon-pencil"></i>
                  </b-button>
                  <b-button
                    pill
                    class="mx-1"
                    size="sm"
                    variant="danger"
                    @click="openDeleteDialog(data.item.id)"
                  >
                    <i class="simple-icon-trash"></i>
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
      </b-colxx>
    </b-row>
    <b-modal
      id="modal-power-plant"
      title="Detalle de la Central Eléctrica"
      modal-class="modal-right"
      no-body
      @close="closeModalDetails"
      no-close-on-backdrop
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-colxx xxs="12" md="6" class="mt-3">
            <p class="text-muted text-small mb-2">Nombre</p>
            <p class="mb-3">
              {{ powerPlant.name }}
            </p>
          </b-colxx>
          <b-colxx xxs="12" md="6" class="mt-3">
            <p class="text-muted text-small mb-2">Proyecto</p>
            <p class="mb-3" v-if="powerPlant.project">
              {{ getProjectById(powerPlant.project.id) }}
            </p>
          </b-colxx>
          <b-colxx xxs="12" class="mt-3 mb-3">
            <p class="text-muted text-small mb-1">Dirección</p>
            <p v-if="powerPlant.commune" class="mb-0 mt-1">
              {{ powerPlant.commune.complete_address }}
            </p>
            <!-- Mapa de Google Maps -->
            <map-marker
              @geoData="updateGeoData"
              :text-input="null"
              :positionObj.sync="positionMarkerShowPowerPlant"
              :only-show="true"
            ></map-marker>
          </b-colxx>
          <b-colxx xxs="12" class="mt-3 mb-5">
            <b-form-checkbox
              id="checkbox-1"
              v-model="powerPlant.is_active"
              name="checkbox-1"
              disabled
            >
              <span class="ms-1"> La Central tiene habilitado un generador </span>
            </b-form-checkbox>
          </b-colxx>
          <b-colxx xxs="12" class="mt-3 mb-5">
            <b-form-checkbox
              id="checkbox-2"
              v-model="powerPlant.central_battery"
              name="checkbox-2"
              disabled
            >
              <span class="ms-1"> La Central tiene batería </span>
            </b-form-checkbox>
          </b-colxx>
          <b-colxx xxs="12" md="6" class="mt-3" v-if="powerPlant.central_code">
            <p class="text-muted text-small mb-2">ID de la Central</p>
            <p class="mb-3">
              {{ powerPlant.central_code }}
            </p>
          </b-colxx>

          <b-colxx xxs="12" md="6" class="mt-3">
            <p class="text-muted text-small mb-2">Configuración de Inyección</p>
            <p class="mb-3" v-if="powerPlant.config_injection">
              {{
                optionsConfigInjection.find(
                  (item) => item.value == powerPlant.config_injection
                ).text
              }}
            </p>
          </b-colxx>
        </b-row>
        <Investors />
      </b-container>
    </b-modal>
    <b-modal
      id="modal-power-plants"
      :title="modeEditor ? 'Editar Central Eléctrica' : 'Agregar Central Eléctrica'"
      modal-class="modal-right"
      @close="closeModal"
      no-close-on-backdrop
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-colxx xss="12" class="mt-3">
            <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
              <b-form-group label="Nombre" class="has-float-label mb-4">
                <b-form-input
                  type="text"
                  v-model="$v.form.name.$model"
                  :state="!$v.form.name.$error"
                />
                <b-form-invalid-feedback v-if="!$v.form.name.required">
                  El nombre de la central eléctrica es requerido.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-checkbox
                id="checkbox-1"
                class="mb-5 mt-4"
                v-model="form.is_active"
                name="checkbox-1"
              >
                <span class="ms-2 mt-1"> La Central tiene habilitado un generador </span>
              </b-form-checkbox>

              <b-form-checkbox
                id="checkbox-1"
                class="mb-5 mt-4"
                v-model="form.central_battery"
                name="checkbox-1"
              >
                <span class="ms-2 mt-1"> La Central tiene batería </span>
              </b-form-checkbox>

              <b-form-group label="ID de la central" class="has-float-label mb-4">
                <b-form-input type="text" v-model="form.central_code" />
              </b-form-group>

              <b-form-group label="Configuración de Inyección" class="has-float-label my-4">
                <b-form-select
                  v-model="$v.form.config_injection.$model"
                  :state="!$v.form.config_injection.$error"
                  :options="optionsConfigInjection"
                  value-field="value"
                  text-field="text"
                  class="w-100"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      Seleccione la Configuración...
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback v-if="!$v.form.config_injection.required">
                  La Configuración es requerida.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group label="Marca" class="w-100 has-float-label mb-4">
                <b-form-select
                  v-model="$v.form.brand.$model"
                  :state="!$v.form.brand.$error"
                  :options="brands"
                  value-field="id"
                  text-field="name"
                  class="w-100"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      Seleccione una marca...
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback v-if="!$v.form.brand.required">
                  La marca es requerida.
                </b-form-invalid-feedback>
              </b-form-group>

              <!-- Mapa de Google Maps -->
              <map-marker
                @geoData="updateGeoData"
                :text-input="form.complete_address"
                :positionObj.sync="positionMarker"
              ></map-marker>

              <div class="d-flex justify-content-end mt-5">
                <b-button
                  type="submit"
                  variant="outline-dark"
                  size="md"
                  :disabled="processing"
                  :class="{
                    'btn-multiple-state btn-shadow': true,
                    'show-spinner': processing
                  }"
                >
                  <span class="spinner d-inline-block">
                    <span class="bounce1"></span>
                    <span class="bounce2"></span>
                    <span class="bounce3"></span>
                  </span>
                  <span class="label"> Guardar </span>
                </b-button>
              </div>
            </b-form>
          </b-colxx>
        </b-row>
      </b-container>
    </b-modal>
    <BaseConfirmModal
      dialogId="delete-power-plant"
      title="Eliminar Central Eléctrica"
      message="¿Estas seguro de que quieres eliminar esta central eléctrica?"
      cancelText="Cancelar"
      proccedText="Aceptar"
      @close="closeDeleteDialog"
      @procced="proccedDelete"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import Investors from './investors.vue'
import adminMixin from '@/mixins/admin'
import MapMarker from '@/components/GoogleMaps/MapMarker.vue'

export default {
  name: 'PowerPlants',
  components: {
    BaseConfirmModal,
    Investors,
    MapMarker
  },
  created() {
    this.getCommunes()
    this.getBrands()
  },
  mixins: [validationMixin, adminMixin],
  data() {
    return {
      selectedToDelete: null,
      processing: false,
      modeEditor: false,
      optionsConfigInjection: [
        {
          text: 'Inyección a la Red',
          value: 1
        },
        {
          text: 'Autoconsumo',
          value: 2
        }
      ],
      form: {
        id: null,
        name: '',
        is_active: false,
        central_battery: false,
        config_injection: null,
        brand: null,
        project: null,
        central_code: null,
        //Data Commune Maps
        country: null,
        city: null,
        postal_code: null,
        street_number: null,
        address: null,
        complete_address: null,
        length: null,
        latitude: null
      },
      powerPlantsTable: {
        fields: [
          {
            key: 'name',
            label: 'Nombre',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'commune',
            label: 'Comuna',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'action',
            label: 'Acción',
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          }
        ]
      },
      selectedImg: null
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      config_injection: {
        required
      },
      brand: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      project: 'project/project',
      powerPlant: 'project/powerPlant',
      powerPlants: 'project/powerPlants',
      brands: 'admin/trademarks',
      tools: 'project/communes'
    }),
    positionMarker() {
      if (this.form.latitude) {
        return {
          lat: Number(this.form.latitude),
          lng: Number(this.form.length)
        }
      } else {
        return { lat: -33.447487, lng: -70.673676 }
      }
    },
    positionMarkerShowPowerPlant() {
      if (this.powerPlant.commune) {
        return {
          lat: Number(this.powerPlant.commune.latitude),
          lng: Number(this.powerPlant.commune.length)
        }
      } else {
        return { lat: -33.447487, lng: -70.673676 }
      }
    }
  },
  methods: {
    ...mapActions({
      getCommunes: 'tools/getCommunes',
      getPowerPlants: 'project/getPowerPlants',
      getBrands: 'admin/getTrademarks',
      getPowerPlant: 'project/getPowerPlant',
      createPowerPlant: 'project/createPowerPlant',
      updatePowerPlant: 'project/updatePowerPlant',
      deletePowerPlant: 'project/deletePowerPlant'
    }),
    ...mapMutations({ setPowerPlant: 'project/setPowerPlant' }),
    updateGeoData(geoData) {
      this.form.country = geoData.country
      this.form.city = geoData.locality
      this.form.postal_code = geoData.postal_code ?? ''
      this.form.street_number = geoData.street_number ?? ''
      ;(this.form.address = geoData.complete_address),
        (this.form.complete_address = geoData.complete_address),
        (this.form.length = geoData.length),
        (this.form.latitude = geoData.latitude)
    },
    openModal(edit = false, itemId = null) {
      this.modeEditor = edit
      this.$bvModal.show('modal-power-plants')
      setTimeout(() => {
        if (itemId) {
          const data = this.powerPlants.find((item) => item.id === itemId)
          const {
            id,
            name,
            project,
            is_active,
            central_battery,
            central_code,
            config_injection,
            brand
          } = data
          this.form = {
            id,
            name,
            is_active,
            central_battery,
            project,
            central_code,
            config_injection,
            brand: brand ? brand.id : null
          }
          this.form.country = data.commune?.country
          this.form.city = data.commune?.city
          this.form.postal_code = data.commune?.postal_code
          this.form.street_number = data.commune?.street_number
          this.form.address = data.commune?.address
          this.form.complete_address = data.commune?.complete_address
          this.form.length = data.commune?.length
          this.form.latitude = data.commune?.latitude
        }
      }, 1000)
    },
    closeModal() {
      this.modeEditor = false
      this.$nextTick(() => {
        this.form = {
          id: null,
          name: '',
          is_active: false,
          central_battery: false,
          project: null,
          country: null,
          config_injection: null,
          brand: null,
          central_code: null,
          city: null,
          postal_code: null,
          street_number: null,
          address: null,
          complete_address: null,
          length: null,
          latitude: null
        }
        this.$v.form.$reset()
        this.$bvModal.hide('modal-power-plants')
      })
    },
    async openDetails(itemId) {
      this.$bvModal.show('modal-power-plant')
      setTimeout(async () => {
        await this.getPowerPlant({
          id: itemId
        })
      }, 1000)
    },
    closeModalDetails() {
      this.setPowerPlant({})
      this.$bvModal.hide('modal-power-plant')
    },
    async formSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.form.latitude) {
        return false
      }
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { id, ...rest } = this.form
        let success
        if (id) {
          success = await this.updatePowerPlant({
            id,
            body: {
              ...rest
            }
          })
        } else {
          success = await this.createPowerPlant({
            body: {
              ...rest,
              project: this.project.id
            }
          })
        }
        this.processing = false
        if (success) {
          this.closeModal()
        }
      }
    },
    openDeleteDialog(itemId) {
      this.selectedToDelete = itemId
      this.$bvModal.show('delete-power-plant')
    },
    closeDeleteDialog() {
      this.selectedToDelete = null
      this.$bvModal.hide('delete-power-plant')
    },
    async proccedDelete() {
      const success = await this.deletePowerPlant({
        id: this.selectedToDelete,
        projectId: this.project.id
      })
      if (success) {
        this.closeDeleteDialog()
      }
    }
  }
}
</script>

<style>
#modal-power-plant.modal-right .modal-dialog {
  max-width: 60vw;
}
</style>
