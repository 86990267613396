<script>
import Layout from '@/router/layouts/main'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import MessagesTicket from '@/components/Custom/Tickets/MessagesTicket.vue'
import { mapActions, mapGetters } from 'vuex'
import { STATUS_TICKET_CLOSED, STATUS_TICKET_ACTIVE } from '@/constants/ticket'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'

/**
 * Blog-detail component
 */
export default {
  page: {
    title: `Detalles del Ticket`,
    meta: [{ name: 'description', content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    MessagesTicket,
    BaseConfirmModal
  },
  data() {
    return {
      STATUS_TICKET_CLOSED,
      STATUS_TICKET_ACTIVE,
      title: `Detalles del Ticket #${this.$route.params.id}`,
      items: [
        {
          text: 'Listado de Tickets',
          to: { name: 'listTickets' }
        },
        {
          text: 'Detalles del Ticket',
          active: true
        }
      ]
    }
  },
  mounted() {
    this.getTicket({ id: this.$route.params.id })
  },
  computed: {
    ...mapGetters({
      ticket: 'ticket/ticket'
    })
  },
  methods: {
    ...mapActions({
      getTicket: 'ticket/getTicket',
      closeTicket: 'ticket/closeTicket',
      actionChangeStatusTicket: 'ticket/changeStatusTicket'
    }),
    openChangeStatusTicketDialog() {
      this.$bvModal.show('change-status-ticket')
    },
    closeChangeStatusTicketDialog() {
      this.$bvModal.hide('change-status-ticket')
    },
    getMessageChangeStatus() {
      return this.ticket?.status == STATUS_TICKET_ACTIVE
        ? '¿Esta seguro que desea cerrar el ticket?'
        : '¿Esta seguro que desea reabrir el ticket?'
    },
    async changeStatusTicket() {
      const success = await this.actionChangeStatusTicket({
        id: this.ticket.id,
        status:
          this.ticket.status == STATUS_TICKET_ACTIVE
            ? STATUS_TICKET_CLOSED
            : STATUS_TICKET_ACTIVE
      })
      if (success) {
        this.closeChangeStatusTicketDialog()
      }
    }
  }
}
</script>

<template>
  <Layout>
    <div class="p-4">
      <PageHeader :title="title" :items="items" />
      <div class="row" v-if="ticket">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="pt-3">
                <div class="row justify-content-center">
                  <div class="col-xl-8">
                    <div>
                      <div class="text-center">
                        <div class="mb-4">
                          <a href="#" class="badge bg-primary font-size-14 mx-1">
                            <i class="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>
                            {{ ticket.type.name }}
                          </a>
                        </div>
                        <h4>{{ ticket.title }}</h4>
                        <p class="text-muted mb-4">
                          <i class="mdi mdi-calendar me-1"></i>
                          {{ ticket.created_at | moment('DD MMMM, YYYY') }}
                        </p>
                        <div class="text-center mt-3">
                          <b-button
                            @click="openChangeStatusTicketDialog"
                            :variant="
                              ticket.status == STATUS_TICKET_ACTIVE
                                ? 'outline-danger'
                                : 'outline-primary'
                            "
                          >
                            {{
                              ticket.status == STATUS_TICKET_ACTIVE ? 'Finalizar' : 'Reabrir'
                            }}
                            Ticket
                          </b-button>
                        </div>
                      </div>

                      <hr />
                      <div class="text-center">
                        <div class="row">
                          <div class="col-sm-4">
                            <div>
                              <h5 class="text-muted mb-3">{{ $t('survey.category') }}</h5>
                              <h5 class="font-size-12">{{ ticket.type.name }}</h5>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mt-4 mt-sm-0">
                              <h5 class="text-muted mb-2">{{ $t('survey.status') }}</h5>
                              <center>
                                <b-badge
                                  :variant="
                                    ticket.status === STATUS_TICKET_ACTIVE
                                      ? 'primary'
                                      : 'danger'
                                  "
                                >
                                  <span class="" style="font-size: 14px">
                                    {{ ticket.status_name }}
                                  </span></b-badge
                                >
                              </center>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mt-4 mt-sm-0">
                              <h5 class="text-muted mb-3">Usuario</h5>
                              <h5 class="font-size-12">{{ ticket.user_fullname }}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <MessagesTicket></MessagesTicket>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>

    <BaseConfirmModal
      dialogId="change-status-ticket"
      :title="`${$t('pages.change-status')} Ticket`"
      :message="`${getMessageChangeStatus()}`"
      :cancelText="$t('pages.cancel')"
      :proccedText="$t('button.accept')"
      @close="closeChangeStatusTicketDialog"
      @procced="changeStatusTicket"
    />
  </Layout>
</template>
