<template>
  <Layout>
    <div class="p-4">
      <b-row>
        <b-colxx xxs="12" class="ml-2 mr-3">
          <b-row align-h="between" class="">
            <div class="d-flex justify-content-between">
              <h1>{{ $t('menu.projects') }}</h1>
              <div class="separator mb-4"></div>
              <div class="mr-3">
                <b-button @click="openModal()" variant="outline-dark"> Nuevo </b-button>
              </div>
            </div>
          </b-row>
          <div class="separator mb-2"></div>
        </b-colxx>
      </b-row>
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <b-card-text>
              <b-row class="d-flex justify-content-between flex-wrap my-3">
                <b-col cols="4">
                  <b-form-group
                    label-align-sm="left"
                    label-for="filterInput"
                    class="mb-0 ml-3 pa-2"
                  >
                    <b-input-group size="md">
                      <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Buscar proyectos"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group
                    label-cols="5"
                    label="Mostrar"
                    label-align="left"
                    label-size="default"
                    label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-2"
                  >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      inline
                      :options="pageOptions"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                :fields="projectsTable.fields"
                :items="projects"
                :per-page="perPage"
                :current-page="currentPage"
                responsive
                hover
                show-empty
                empty-text="No hay proyectos registrados."
                empty-filtered-text="No hay proyectos para filtrar."
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="ml-3"
              >
                <template #cell(name)="data">
                  <img
                    class="img__avatar mx-1"
                    width="50"
                    v-if="data.item.image_project"
                    :src="data.item.image_project"
                  />
                  <span class="ml-2">
                    {{ data.value }}
                  </span>
                </template>
                <template #cell(action)="data">
                  <div class="text-center">
                    <b-button
                      class="mx-1"
                      pill
                      size="sm"
                      variant="info"
                      @click="openDetails(data.item.id)"
                    >
                      <i class="simple-icon-eye"></i>
                    </b-button>
                    <b-button
                      pill
                      class="mx-1"
                      size="sm"
                      variant="success"
                      @click="openModal(true, data.item.id)"
                    >
                      <i class="simple-icon-pencil"></i>
                    </b-button>
                    <b-button
                      pill
                      class="mx-1"
                      size="sm"
                      variant="danger"
                      @click="openDeleteDialog(data.item.id)"
                    >
                      <i class="simple-icon-trash"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
              <b-row class="d-flex justify-content-center align-items-center flex-wrap pt-0">
                <div>
                  <b-pagination
                    pills
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="table-users"
                    class="ml-2"
                  ></b-pagination>
                </div>
              </b-row>
            </b-card-text>
          </b-card>
        </b-colxx>
      </b-row>
      <b-modal
        id="modal-projects"
        :title="modeEditor ? 'Editar Proyecto' : 'Agregar Proyecto'"
        modal-class="modal-right"
        @close="closeModal"
        no-close-on-backdrop
        hide-footer
      >
        <b-container fluid>
          <b-row>
            <b-colxx xxs="12" class="d-flex justify-content-center">
              <b-avatar
                :src="
                  selectedImg
                    ? selectedImg
                    : form.imageUrl
                    ? form.imageUrl
                    : require('@/assets/images/app/panels/panel.jpg')
                "
                square
                size="7rem"
                class="img__avatar"
                badge
                badge-variant="light"
                id="avatar-project"
              >
                <template #badge>
                  <b-button
                    v-if="selectedImg"
                    class="px-2"
                    size="sm"
                    variant="danger"
                    @click="removeImgSelected"
                  >
                    <i class="simple-icon-trash"></i>
                  </b-button>
                  <b-button class="px-2" size="sm" variant="primary" @click="pickFile">
                    <i class="simple-icon-cloud-upload"></i>
                  </b-button>
                </template>
              </b-avatar>
            </b-colxx>
            <b-colxx xss="12" class="mt-3">
              <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
                <input
                  ref="inputFile"
                  @change="onFilePicked"
                  type="file"
                  style="display: none"
                  accept="image/png, image/jpg, image/jpeg"
                />
                <b-form-group label="Nombre" class="has-float-label mb-4">
                  <b-form-input
                    type="text"
                    v-model="$v.form.name.$model"
                    :state="!$v.form.name.$error"
                  />
                  <b-form-invalid-feedback v-if="!$v.form.name.required">
                    El nombre del proyecto es requerido.
                  </b-form-invalid-feedback>
                </b-form-group>
                <div class="d-flex justify-content-end">
                  <b-button
                    type="submit"
                    variant="outline-dark"
                    size="md"
                    :disabled="processing"
                    :class="{
                      'btn-multiple-state btn-shadow': true,
                      'show-spinner': processing
                    }"
                  >
                    <span class="spinner d-inline-block">
                      <span class="bounce1"></span>
                      <span class="bounce2"></span>
                      <span class="bounce3"></span>
                    </span>
                    <span class="label"> Guardar </span>
                  </b-button>
                </div>
              </b-form>
            </b-colxx>
          </b-row>
        </b-container>
      </b-modal>
      <b-modal
        id="modal-project"
        title="Detalle del Proyecto"
        modal-class="modal-right"
        body-class="px-1"
        no-body
        @close="closeModalDetails"
        no-close-on-backdrop
        hide-footer
      >
        <b-container fluid>
          <b-row>
            <b-colxx xxs="12" class="d-flex justify-content-center">
              <b-avatar
                :src="project.image_project || '/assets/img/otras/panel.jpg'"
                square
                size="7rem"
                class="img-actions"
              >
              </b-avatar>
            </b-colxx>
            <b-colxx xss="12" class="mt-3">
              <p class="text-muted text-small mb-2">Nombre</p>
              <p class="mb-3">
                {{ project.name }}
              </p>
            </b-colxx>
          </b-row>
          <PowerPlants />
          <ProjectUsers />
        </b-container>
      </b-modal>
      <BaseConfirmModal
        dialogId="delete-project"
        title="Eliminar Proyecto"
        message="¿Estas seguro de que quieres eliminar este proyecto?"
        cancelText="Cancelar"
        proccedText="Aceptar"
        @close="closeDeleteDialog"
        @procced="proccedDelete"
      />
    </div>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/main'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import PowerPlants from './powerPlants'
import ProjectUsers from './projectUsers'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')

export default {
  name: 'Projects',
  components: {
    Layout,
    BaseConfirmModal,
    PowerPlants,
    ProjectUsers
  },
  mixins: [validationMixin],
  created() {
    this.getProjects()
    this.getEquipments()
    this.getUsers()
  },
  data() {
    return {
      selectedToDelete: null,
      processing: false,
      modeEditor: false,
      form: {
        id: null,
        name: '',
        image_project: null,
        imageUrl: null
      },
      projectsTable: {
        fields: [
          {
            key: 'id',
            label: 'ID',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'name',
            label: 'Nombre',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'action',
            label: 'Acción',
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          }
        ]
      },
      selectedImg: null,
      perPage: 10,
      pageOptions: [10, 20, 30],
      currentPage: 1,
      totalRows: 1,
      filterOn: [],
      filter: null
    }
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({ projects: 'project/projects', project: 'project/project' }),
    rows() {
      return this.projects.length
    }
  },
  methods: {
    ...mapActions({
      getProjects: 'project/getProjects',
      getProject: 'project/getProject',
      createProject: 'project/createProject',
      updateProject: 'project/updateProject',
      deleteProject: 'project/deleteProject',
      getPowerPlants: 'project/getPowerPlants',
      getEquipments: 'admin/getEquipments',
      getUsers: 'project/getUsers'
    }),
    ...mapMutations({
      setProject: 'project/setProject',
      setPowerPlants: 'project/setPowerPlants'
    }),
    openModal(edit = false, itemId = null) {
      this.modeEditor = edit
      if (itemId) {
        const data = this.projects.find((item) => item.id === itemId)
        const { id, name, image_project } = data
        this.form = {
          id,
          name,
          imageUrl: image_project,
          image_project: null
        }
      }
      this.$bvModal.show('modal-projects')
    },
    closeModal() {
      this.modeEditor = false
      this.$nextTick(() => {
        this.form = {
          id: null,
          name: '',
          image_project: null,
          imageUrl: null
        }
        this.$v.form.$reset()
        this.$bvModal.hide('modal-projects')
      })
    },
    async openDetails(itemId) {
      const success = await this.getProject({
        id: itemId
      })
      if (success) {
        // this.getPowerPlants()
        this.$bvModal.show('modal-project')
      }
    },
    closeModalDetails() {
      this.setProject({})
      this.setPowerPlants([])
      this.$bvModal.hide('modal-project')
    },
    async formSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { id, ...rest } = this.form
        let success
        if (id) {
          success = await this.updateProject({
            id,
            body: { ...rest }
          })
        } else {
          success = await this.createProject({
            body: { ...rest }
          })
        }
        this.processing = false
        if (success) {
          this.removeImgSelected()
          this.closeModal()
        }
      }
    },
    removeImgSelected() {
      this.form.image_project = null
      this.selectedImg = null
    },
    pickFile() {
      this.$refs.inputFile.click()
    },
    onFilePicked(e) {
      const { files } = e.target
      const fr = new FileReader()
      if (files[0] !== undefined) {
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.form.image_project = files[0]
          this.selectedImg = fr.result
        })
      }
    },
    openDeleteDialog(itemId) {
      this.selectedToDelete = itemId
      this.$bvModal.show('delete-project')
    },
    closeDeleteDialog() {
      this.selectedToDelete = null
      this.$bvModal.hide('delete-project')
    },
    async proccedDelete() {
      const success = await this.deleteProject({
        id: this.selectedToDelete
      })
      if (success) {
        this.closeDeleteDialog()
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<style>
#modal-project.modal-right .modal-dialog {
  max-width: 70vw;
}
#avatar-project > span.b-avatar-badge.badge-light {
  padding: 0 0 0.2rem 0 !important;
  background-color: transparent;
  min-height: auto;
  min-width: auto;
}
</style>
