<script>
import { mapActions, mapGetters } from 'vuex'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import SenderMessageTicket from './SenderMessageTicket.vue'
import helpers from '@/utils/helpers'

export default {
  components: {
    BaseConfirmModal,
    SenderMessageTicket
  },
  mounted() {
    this.getMessagesTicket({
      id: this.$route.params.id
    })
  },
  computed: {
    ...mapGetters({
      ticket: 'ticket/ticket',
      messagesTicket: 'ticket/messagesTicket',
      currentUser: 'user/currentUser'
    })
  },
  methods: {
    ...mapActions({
      getMessagesTicket: 'ticket/getMessagesTicket',
      deleteMessageTicket: 'ticket/deleteMessageTicket'
    }),
    ifTimeMessageExceedsFifteenMinutes(timeMessage) {
      return Math.abs(this.$moment(timeMessage).diff(this.$moment(), 'minutes')) > 15
    },
    getFileExtensionFromUrl(url) {
      return helpers.getFileExtensionFromStringUrl(url)
    },
    openDeleteMessageDialog(messageId) {
      this.selectedToDelete = messageId
      this.$bvModal.show('delete-message')
    },
    closeDeleteMessageDialog() {
      this.selectedToDelete = null
      this.$bvModal.hide('delete-message')
    },
    async deleteMessage() {
      const success = await this.deleteMessageTicket({
        id: this.selectedToDelete
      })
      if (success) {
        this.closeDeleteMessageDialog()
      }
    }
  }
}
</script>

<template>
  <div class="mt-4">
    <div class="mt-5">
      <h5 class="font-size-15">
        <i class="bx bx-message-dots text-muted align-middle me-1"></i>
        Mensajes :
      </h5>
      <div v-if="currentUser">
        <div
          class="d-flex py-3"
          v-for="(message, index) in messagesTicket"
          :key="index"
          :class="{ 'border-top': !index }"
        >
          <div class="avatar-xs me-3">
            <div
              class="avatar-title rounded-circle bg-light"
              :class="{
                'text-primary': message.customuser.id === currentUser.id,
                'text-warning': message.customuser.id !== currentUser.id
              }"
            >
              <i class="bx bxs-user"></i>
            </div>
          </div>
          <div class="flex-grow-1">
            <h5 class="font-size-14 mb-1">
              {{ message.user_fullname }}
              <small
                class="text-muted float-end"
                v-if="
                  message.customuser.id === currentUser.id &&
                  !ifTimeMessageExceedsFifteenMinutes(message.created_at)
                "
              >
                <b-button
                  @click="openDeleteMessageDialog(message.id)"
                  pill
                  class="mx-1"
                  size="sm"
                  variant="danger"
                >
                  <i class="simple-icon-trash"></i>
                </b-button>
              </small>
            </h5>
            <p class="text-muted">
              {{ message.content }}
            </p>
            <div class="d-flex align-items-center">
              <small class="text-muted d-flex align-items-center">
                <i class="bx bx-time mx-1"></i>
                {{ message.time_ago }}
              </small>
              <a
                v-if="message.media"
                rel="noopener noreferrer"
                target="_blank"
                class="text-primary mx-3 mt-1"
                :href="message.media"
                download
              >
                <i class="bx bx-file"></i>
                <small class="text-primary">
                  Descargar Adjunto (.{{ getFileExtensionFromUrl(message.media) }})
                </small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Componente para enviar mensaje de ticket -->
    <SenderMessageTicket></SenderMessageTicket>

    <BaseConfirmModal
      dialogId="delete-message"
      :title="`${$t('pages.delete')} Mensaje`"
      :message="`¿ ${$t('general.question_delete')} Mensaje ?`"
      :cancelText="$t('pages.cancel')"
      :proccedText="$t('button.accept')"
      @close="closeDeleteMessageDialog"
      @procced="deleteMessage"
    />
  </div>
</template>
