export default {
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[0-9\s]*$/.test(char)) return true
      // Match with regex
      else e.preventDefault() // If not match, don't add to input text
    }
  }
}
