<template>
  <div class="mt-4">
    <h5 class="font-size-16 mb-3">
      <i class="bx bx-message-dots text-muted align-middle me-1"></i>
      Enviar un Mensaje
    </h5>

    <b-form @submit.prevent="sendMessage">
      <b-form-group label="Mensaje" class="has-float-label mb-3">
        <b-form-textarea
          class="form-control my-2"
          id="commentmessage-input"
          placeholder="Escribe tu mensaje..."
          rows="3"
          :state="!$v.form.message.$error"
          v-model="$v.form.message.$model"
        ></b-form-textarea>
        <span class="text-danger" v-if="$v.form.message.$invalid && sended">
          {{ $t('validation.message_required') }}
        </span>
      </b-form-group>

      <div class="">
        <div class="mt-">
          <label for="formFile" class="form-label">Archivo</label>
          <input @change="onFilePicked" class="form-control" type="file" id="formFile" />
        </div>
      </div>
      <div class="text-end mt-3">
        <b-button type="submit" :disabled="processing" variant="outline-dark">
          <b-spinner v-if="processing" class="mx-2" small></b-spinner>
          Enviar Mensaje
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')
export default {
  name: 'SenderMessageTicket',
  mixins: [validationMixin],
  data() {
    return {
      processing: false,
      sended: false,
      form: {
        message: '',
        media: null
      }
    }
  },
  computed: {
    ...mapGetters({
      ticket: 'ticket/ticket',
      currentUser: 'user/currentUser'
    })
  },
  methods: {
    ...mapActions({
      sendMessageTicket: 'ticket/sendMessageTicket'
    }),
    async sendMessage() {
      this.sended = true
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { media, message } = this.form
        try {
          await this.sendMessageTicket({
            body: {
              media,
              content: message,
              customuser: this.currentUser.id,
              ticket: this.ticket.id
            }
          })
          this.sended = false
          this.$v.$reset()
        } catch (error) {
          return error
        } finally {
          this.processing = false
          this.form.message = ''
          this.form.media = null
        }
      }
    },
    onFilePicked(e) {
      const { files } = e.target
      const fr = new FileReader()
      if (files[0] !== undefined) {
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.form.media = files[0]
        })
      }
    }
  },
  validations: {
    form: {
      message: {
        required
      }
    }
  }
}
</script>

<style></style>
