import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      communes: 'tool/communes',
      trademarks: 'admin/trademarks',
      equipments: 'admin/equipments',
      powerPlants: 'project/powerPlants',
      projects: 'project/projects',
      users: 'project/users',
      mppts: 'mppt/mppts',
      strings: 'string/strings',
      investors: 'project/investors'
    })
  },
  methods: {
    getTrademarkById(trademarkId) {
      const trademark = this.trademarks.find((b) => b.id === trademarkId)
      return trademark ? trademark.name : '---'
    },
    getEquipmentById(equipmentId) {
      const equipment = this.equipments.find((b) => b.id === equipmentId)
      return equipment ? equipment.name : '---'
    },
    getPowerPlantById(powerPlantId) {
      const powerPlant = this.powerPlants.find((b) => b.id === powerPlantId)
      return powerPlant ? powerPlant.name : '---'
    },
    getProjectById(projectId) {
      const project = this.projects.find((b) => b.id === projectId)
      return project ? project.name : '---'
    },
    getInvestorById(investorId) {
      const investor = this.investors.find((b) => b.id === investorId)
      return investor ? this.getEquipmentById(investor.equipment) : '---'
    },
    getMpptById(mpptId) {
      const mppt = this.mppts.find((b) => b.id === mpptId)
      return mppt ? mppt.name : '---'
    },
    getStringById(stringId) {
      const string = this.strings.find((b) => b.id === stringId)
      return string ? string.name : '---'
    },
    getUserById(userId) {
      const user = this.users.find((b) => b.id === userId)
      return user ? user : {}
    },
    getCommuneById(communeId) {
      const commune = this.communes.find((b) => b.id === communeId)
      return commune ? commune : { name: '---' }
    }
  }
}
