<template>
  <div>
    <b-row>
      <div class="d-flex justify-content-end mb-3">
        <b-button @click="openModal()" variant="outline-dark">
          {{ $t('survey.new') }}
        </b-button>
      </div>
      <b-colxx xxs="12">
        <b-card no-body>
          <b-card-title class="mb-0 py-2 px-3"> Strings </b-card-title>
          <b-card-text>
            <b-table
              :fields="stringTable.fields"
              :items="strings"
              responsive
              hover
              show-empty
              :empty-text="$t('validation.empty_strings')"
            >
              <template #cell(action)="data">
                <div class="text-center">
                  <b-button
                    class="mx-1"
                    pill
                    size="sm"
                    variant="info"
                    @click="openDetails(data.item.id)"
                  >
                    <i class="simple-icon-eye"></i>
                  </b-button>
                  <b-button
                    pill
                    class="mx-1"
                    size="sm"
                    variant="success"
                    @click="openModal(true, data.item.id)"
                  >
                    <i class="simple-icon-pencil"></i>
                  </b-button>
                  <b-button
                    pill
                    class="mx-1"
                    size="sm"
                    variant="danger"
                    @click="openDeleteDialog(data.item.id)"
                  >
                    <i class="simple-icon-trash"></i>
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
      </b-colxx>
    </b-row>
    <b-modal
      id="modal-string"
      :title="$t('pages.string.details')"
      modal-class="modal-right"
      no-body
      @close="closeModalDetails"
      no-close-on-backdrop
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-colxx xss="12" class="mt-3">
            <p class="text-muted text-small mb-2">
              {{ $t('forms.name') }}
            </p>
            <p class="mb-3">
              {{ string.name }}
            </p>
          </b-colxx>
          <b-colxx xss="12" class="mt-3">
            <p class="text-muted text-small mb-2">MPPT</p>
            <p class="mb-3">
              {{ getMpptById(string.mppt) }}
            </p>
          </b-colxx>
        </b-row>
        <Panels />
      </b-container>
    </b-modal>
    <b-modal
      id="modal-strings"
      :title="modeEditor ? `${$t('survey.edit')} String` : `${$t('forms.add')} String`"
      modal-class="modal-right"
      @close="closeModal"
      no-close-on-backdrop
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-colxx xss="12" class="mt-3">
            <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
              <b-form-group label="Nombre" class="has-float-label mb-4">
                <b-form-input
                  type="text"
                  v-model="$v.form.name.$model"
                  :state="!$v.form.name.$error"
                />
                <b-form-invalid-feedback v-if="!$v.form.name.required">
                  {{ $t('validation.name_required') }}
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="d-flex justify-content-end">
                <b-button
                  type="submit"
                  variant="outline-dark"
                  size="md"
                  :disabled="processing"
                  :class="{
                    'btn-multiple-state btn-shadow': true,
                    'show-spinner': processing
                  }"
                >
                  <span class="spinner d-inline-block">
                    <span class="bounce1"></span>
                    <span class="bounce2"></span>
                    <span class="bounce3"></span>
                  </span>
                  <span class="label">
                    {{ $t('survey.save') }}
                  </span>
                </b-button>
              </div>
            </b-form>
          </b-colxx>
        </b-row>
      </b-container>
    </b-modal>
    <BaseConfirmModal
      dialogId="delete-string"
      :title="`${$t('pages.delete')} String`"
      :message="`¿ ${$t('general.question_delete')} String ?`"
      :cancelText="$t('pages.cancel')"
      :proccedText="$t('button.accept')"
      @close="closeDeleteDialog"
      @procced="proccedDelete"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import Panels from './panels.vue'
import adminMixin from '@/mixins/admin'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')
export default {
  name: 'Strings',
  components: {
    BaseConfirmModal,
    Panels
  },
  mixins: [validationMixin, adminMixin],
  data() {
    return {
      selectedToDelete: null,
      processing: false,
      modeEditor: false,
      form: {
        id: null,
        name: '',
        mppt: null
      },
      stringTable: {
        fields: [
          {
            key: 'name',
            label: this.$t('forms.name'),
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'action',
            label: this.$t('dropdowns.action'),
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          }
        ]
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({ mppt: 'mppt/mppt', string: 'string/string', strings: 'string/strings' })
  },
  methods: {
    ...mapActions({
      getStrings: 'string/getStrings',
      getString: 'string/getString',
      createString: 'string/createString',
      updateString: 'string/updateString',
      deleteString: 'string/deleteString'
    }),
    ...mapMutations({ setString: 'string/setString' }),
    openModal(edit = false, itemId = null) {
      this.modeEditor = edit
      if (itemId) {
        const data = this.strings.find((item) => item.id === itemId)
        const { id, name, mppt } = data
        this.form = {
          id,
          name,
          mppt
        }
      }
      this.$bvModal.show('modal-strings')
    },
    closeModal() {
      this.modeEditor = false
      this.$nextTick(() => {
        this.form = {
          id: null,
          name: '',
          mppt: null
        }
        this.$v.form.$reset()
        this.$bvModal.hide('modal-strings')
      })
    },
    async openDetails(itemId) {
      const success = await this.getString({
        id: itemId
      })
      if (success) {
        this.$bvModal.show('modal-string')
      }
    },
    closeModalDetails() {
      this.setString({})
      this.$bvModal.hide('modal-string')
    },
    async formSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { id, ...rest } = this.form
        let success
        if (id) {
          success = await this.updateString({
            id,
            body: {
              ...rest
            }
          })
        } else {
          success = await this.createString({
            body: {
              ...rest,
              mppt: this.mppt.id
            }
          })
        }
        this.processing = false
        if (success) {
          this.closeModal()
        }
      }
    },
    openDeleteDialog(itemId) {
      this.selectedToDelete = itemId
      this.$bvModal.show('delete-string')
    },
    closeDeleteDialog() {
      this.selectedToDelete = null
      this.$bvModal.hide('delete-string')
    },
    async proccedDelete() {
      const success = await this.deleteString({
        id: this.selectedToDelete,
        mpptId: this.mppt.id
      })
      if (success) {
        this.closeDeleteDialog()
      }
    }
  }
}
</script>

<style>
#modal-string.modal-right .modal-dialog {
  max-width: 50vw;
}
</style>
