<template>
  <div>
    <b-row class="mt-4" justify="end">
      <div class="d-flex justify-content-end mb-3">
        <b-button @click="openModal()" variant="outline-dark"> Nuevo </b-button>
      </div>
      <b-colxx xxs="12">
        <b-card no-body>
          <b-card-title class="mb-0 py-2 px-3"> Usuarios </b-card-title>
          <b-card-text>
            <b-table
              :fields="projectUsersTable.fields"
              :items="projectUsers"
              responsive
              hover
              show-empty
              empty-text="No hay usuarios registrados."
            >
              <template #cell(name)="data">
                {{ getUserById(data.item.customuser.id).first_name }}
                {{ getUserById(data.item.customuser.id).last_name }}
              </template>
              <template #cell(action)="data">
                <div class="text-center">
                  <b-button
                    class="mx-1"
                    pill
                    size="sm"
                    variant="info"
                    @click="openDetails(data.item.id)"
                  >
                    <i class="simple-icon-eye"></i>
                  </b-button>
                  <b-button
                    pill
                    class="mx-1"
                    size="sm"
                    variant="success"
                    @click="openModal(true, data.item.id)"
                  >
                    <i class="simple-icon-pencil"></i>
                  </b-button>
                  <b-button
                    pill
                    class="mx-1"
                    size="sm"
                    variant="danger"
                    @click="openDeleteDialog(data.item.id)"
                  >
                    <i class="simple-icon-trash"></i>
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
      </b-colxx>
    </b-row>
    <b-modal
      id="modal-project-user"
      title="Detalle del Usuario"
      modal-class="modal-right"
      no-body
      @close="closeModalDetails"
      no-close-on-backdrop
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-colxx xss="12" class="mt-3">
            <p class="text-muted text-small mb-2">Nombre</p>
            <p class="mb-3">
              {{ getUserById(projectUser.user ? projectUser.user.id : null).first_name }}
              {{ getUserById(projectUser.customuser ? projectUser.user.id : null).last_name }}
            </p>
            <p class="text-muted text-small mb-2">Email</p>
            <p class="mb-3">
              {{ getUserById(projectUser.user ? projectUser.user.id : null).email }}
            </p>
            <p
              v-if="getUserById(projectUser.user ? projectUser.user.id : null).phone"
              class="text-muted text-small mb-2"
            >
              Telefono
            </p>
            <p
              v-if="getUserById(projectUser.user ? projectUser.user.id : null).phone"
              class="mb-3"
            >
              {{ getUserById(projectUser.user ? projectUser.user.id : null).phone }}
            </p>
            <p
              v-if="getUserById(projectUser.user ? projectUser.user.id : null).address"
              class="text-muted text-small mb-2"
            >
              Dirección
            </p>
            <p
              v-if="getUserById(projectUser.user ? projectUser.user.id : null).address"
              class="mb-3"
            >
              {{ getUserById(projectUser.user ? projectUser.user.id : null).address }}
            </p>
          </b-colxx>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      id="modal-project-users"
      :title="modeEditor ? 'Editar Usuario' : 'Agregar Usuario'"
      modal-class="modal-right"
      @close="closeModal"
      no-close-on-backdrop
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-colxx xss="12" class="mt-3">
            <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
              <b-form-group label="Usuario" class="w-100 has-float-label mb-4">
                <b-form-select
                  v-model="$v.form.customuser.$model"
                  :state="!$v.form.customuser.$error"
                  :options="users"
                  value-field="id"
                  text-field="fullname"
                  class="w-100"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      Seleccione...
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback v-if="!$v.form.customuser.required">
                  El usuario es requerido.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-card v-if="form.customuser" no-body class="mb-4">
                <b-container>
                  <b-row>
                    <b-colxx xss="12" class="mt-3">
                      <p class="text-muted text-small mb-2">Nombre</p>
                      <p class="mb-3">
                        {{ getUserById(form.customuser).first_name }}
                        {{ getUserById(form.customuser).last_name }}
                      </p>
                      <p class="text-muted text-small mb-2">Correo</p>
                      <p class="mb-3">
                        {{ getUserById(form.customuser).email }}
                      </p>
                      <p
                        v-if="getUserById(form.customuser).phone"
                        class="text-muted text-small mb-2"
                      >
                        Telefono
                      </p>
                      <p v-if="getUserById(form.customuser).phone" class="mb-3">
                        {{ getUserById(form.customuser).phone }}
                      </p>
                    </b-colxx>
                  </b-row>
                </b-container>
              </b-card>
              <div class="d-flex justify-content-end">
                <b-button
                  type="submit"
                  variant="outline-dark"
                  size="md"
                  :disabled="processing"
                  :class="{
                    'btn-multiple-state btn-shadow': true,
                    'show-spinner': processing
                  }"
                >
                  <span class="spinner d-inline-block">
                    <span class="bounce1"></span>
                    <span class="bounce2"></span>
                    <span class="bounce3"></span>
                  </span>
                  <span class="label"> Guardar </span>
                </b-button>
              </div>
            </b-form>
          </b-colxx>
        </b-row>
      </b-container>
    </b-modal>
    <BaseConfirmModal
      dialogId="delete-project-user"
      title="Eliminar Usuario"
      message="¿Estas seguro de que quieres eliminar este usuario?"
      cancelText="Cancelar"
      proccedText="Aceptar"
      @close="closeDeleteDialog"
      @procced="proccedDelete"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import adminMixin from '@/mixins/admin'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')

export default {
  name: 'ProjectUsers',
  components: {
    BaseConfirmModal
  },
  mixins: [validationMixin, adminMixin],
  data() {
    return {
      selectedToDelete: null,
      processing: false,
      modeEditor: false,
      form: {
        id: null,
        customuser: null,
        user_project: null
      },
      projectUsersTable: {
        fields: [
          {
            key: 'name',
            label: 'Nombre',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'action',
            label: 'Acción',
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          }
        ]
      },
      selectedImg: null
    }
  },
  validations: {
    form: {
      customuser: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      project: 'project/project',
      projectUser: 'project/projectUser',
      projectUsers: 'project/projectUsers',
      users: 'project/users'
    })
  },
  methods: {
    ...mapActions({
      getProjectUsers: 'project/getProjectUsers',
      getProjectUser: 'project/getProjectUser',
      createProjectUser: 'project/createProjectUser',
      updateProjectUser: 'project/updateProjectUser',
      deleteProjectUser: 'project/deleteProjectUser'
    }),
    ...mapMutations({ setProjectUser: 'project/setProjectUser' }),
    openModal(edit = false, itemId = null) {
      this.modeEditor = edit
      if (itemId) {
        const data = this.projectUsers.find((item) => item.id === itemId)
        const { id, customuser, user_project } = data
        this.form = {
          id,
          customuser: customuser.id,
          user_project
        }
      }
      this.$bvModal.show('modal-project-users')
    },
    closeModal() {
      this.modeEditor = false
      this.$nextTick(() => {
        this.form = {
          id: null,
          customuser: null,
          user_project: null
        }
        this.$v.form.$reset()
        this.$bvModal.hide('modal-project-users')
      })
    },
    async openDetails(itemId) {
      const success = await this.getProjectUser({
        id: itemId
      })
      if (success) {
        this.$bvModal.show('modal-project-user')
      }
    },
    closeModalDetails() {
      this.setProjectUser({})
      this.$bvModal.hide('modal-project-user')
    },
    async formSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { id, ...rest } = this.form
        let success
        if (id) {
          success = await this.updateProjectUser({
            id,
            body: {
              ...rest
            }
          })
        } else {
          success = await this.createProjectUser({
            body: {
              ...rest,
              user_project: this.project.id
            }
          })
        }
        this.processing = false
        if (success) {
          this.closeModal()
        }
      }
    },
    openDeleteDialog(itemId) {
      this.selectedToDelete = itemId
      this.$bvModal.show('delete-project-user')
    },
    closeDeleteDialog() {
      this.selectedToDelete = null
      this.$bvModal.hide('delete-project-user')
    },
    async proccedDelete() {
      const success = await this.deleteProjectUser({
        id: this.selectedToDelete,
        projectId: this.project.id
      })
      if (success) {
        this.closeDeleteDialog()
      }
    }
  }
}
</script>

<style></style>
