<template>
  <div>
    <b-row class="mt-4">
      <div class="d-flex justify-content-between">
        <b-card-title class="mb-2 py-2">
          {{ $t('menu.projects') }}
        </b-card-title>
        <b-button @click="openModal()" variant="outline-dark">
          {{ $t('survey.new') }}
        </b-button>
      </div>
      <b-colxx xxs="12">
        <b-card no-body>
          <b-card-text>
            <b-table
              :fields="tagProjectsTable.fields"
              :items="tagProjects"
              responsive
              hover
              show-empty
              :empty-text="$t('validation.empty_projects')"
            >
              <template #cell(name)="data">
                {{ getProjectById(data.item.project) }}
              </template>
              <template #cell(action)="data">
                <div class="text-center">
                  <b-button
                    pill
                    size="sm"
                    class="mx-1"
                    variant="info"
                    @click="openDetails(data.item.project)"
                  >
                    <i class="simple-icon-eye"></i>
                  </b-button>
                  <b-button
                    pill
                    class="mx-1"
                    size="sm"
                    variant="success"
                    @click="openModal(true, data.item.id)"
                  >
                    <i class="simple-icon-pencil"></i>
                  </b-button>
                  <b-button
                    pill
                    class="mx-1"
                    size="sm"
                    variant="danger"
                    @click="openDeleteDialog(data.item.project)"
                  >
                    <i class="simple-icon-trash"></i>
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
      </b-colxx>
    </b-row>
    <b-modal
      id="modal-tag-project-details"
      :title="$t('pages.projects.details')"
      modal-class="modal-right"
      no-body
      @close="closeModalDetails"
      no-close-on-backdrop
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-colxx xss="12" class="mt-3">
            <p class="text-muted text-small mb-2">Nombre</p>
            <p class="mb-3">
              {{ getProjectById(showTagProject.project ? showTagProject.project.id : null) }}
            </p>
          </b-colxx>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      id="modal-tag-project"
      :title="
        modeEditor
          ? `${$t('survey.edit')} ${$t('general.project')}`
          : `${$t('forms.add')} ${$t('general.project')}`
      "
      modal-class="modal-right"
      @close="closeModal"
      no-close-on-backdrop
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-colxx xss="12" class="mt-3">
            <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
              <b-form-group :label="$t('general.project')" class="w-100 has-float-label mb-4">
                <b-form-select
                  class="w-100"
                  v-model="$v.form.project.$model"
                  :state="!$v.form.project.$error"
                  :options="
                    projects.filter(
                      (project) =>
                        !tagProjects.some((tagProject) => tagProject.project === project.id) ||
                        modeEditor
                    )
                  "
                  value-field="id"
                  text-field="name"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      Seleccione...
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback v-if="!$v.form.project.required">
                  El proyecto es requerido.
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="d-flex justify-content-end">
                <b-button
                  type="submit"
                  variant="outline-dark"
                  size="md"
                  :disabled="processing"
                  :class="{
                    'btn-multiple-state btn-shadow': true,
                    'show-spinner': processing
                  }"
                >
                  <span class="spinner d-inline-block">
                    <span class="bounce1"></span>
                    <span class="bounce2"></span>
                    <span class="bounce3"></span>
                  </span>
                  <span class="label">
                    {{ $t('survey.save') }}
                  </span>
                </b-button>
              </div>
            </b-form>
          </b-colxx>
        </b-row>
      </b-container>
    </b-modal>
    <BaseConfirmModal
      dialogId="delete-tag-project"
      :title="`${$t('pages.delete')} ${$t('general.project')}`"
      :message="`¿ ${$t('general.question_delete')} ${$t('general.project')} ?`"
      :cancelText="$t('pages.cancel')"
      :proccedText="$t('button.accept')"
      @close="closeDeleteDialog"
      @procced="proccedDelete"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import adminMixin from '@/mixins/admin'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')

export default {
  name: 'tagsprojects',
  components: {
    BaseConfirmModal
  },
  mixins: [validationMixin, adminMixin],
  data() {
    return {
      selectedToDelete: null,
      processing: false,
      modeEditor: false,
      form: {
        id: null,
        project: null,
        tag: null
      },
      tagProjectsTable: {
        fields: [
          {
            key: 'name',
            label: 'Nombre',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'action',
            label: 'Acción',
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          }
        ]
      },
      selectedImg: null
    }
  },
  validations: {
    form: {
      project: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      tag: 'tag/tag',
      tagProject: 'tag/tagProject',
      tagProjects: 'tag/tagProjects',
      projects: 'project/projects'
    }),
    showTagProject() {
      if (this.tagProject?.length) {
        return this.tagProject[0]
      }
      return {
        project: {}
      }
    }
  },
  methods: {
    ...mapActions({
      getTagProjects: 'tag/getTagProjects',
      getTagProject: 'tag/getTagProject',
      createTagProject: 'tag/createTagProject',
      updateTagProject: 'tag/updateTagProject',
      deleteTagProject: 'tag/deleteTagProject'
    }),
    ...mapMutations({ setTagProject: 'tag/setTagProject' }),
    openModal(edit = false, itemId = null) {
      this.modeEditor = edit
      if (itemId) {
        const data = this.tagProjects.find((item) => item.id === itemId)
        const { id, project, tag } = data
        this.form = {
          id,
          project,
          tag
        }
      }
      this.$bvModal.show('modal-tag-project')
    },
    closeModal() {
      this.modeEditor = false
      this.$nextTick(() => {
        this.form = {
          id: null,
          project: null,
          tag: null
        }
        this.$v.form.$reset()
        this.$bvModal.hide('modal-tag-project')
      })
    },
    async openDetails(itemId) {
      const success = await this.getTagProject({
        id: itemId
      })
      if (success) {
        this.$bvModal.show('modal-tag-project-details')
      }
    },
    closeModalDetails() {
      this.setTagProject({})
      this.$bvModal.hide('modal-tag-project-details')
    },
    async formSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { id, ...rest } = this.form
        let success
        if (id) {
          success = await this.updateTagProject({
            id,
            body: {
              ...rest
            }
          })
        } else {
          success = await this.createTagProject({
            body: {
              ...rest,
              tag: this.tag.id
            }
          })
        }
        this.processing = false
        if (success) {
          this.closeModal()
        }
      }
    },
    openDeleteDialog(itemId) {
      this.selectedToDelete = itemId
      this.$bvModal.show('delete-tag-project')
    },
    closeDeleteDialog() {
      this.selectedToDelete = null
      this.$bvModal.hide('delete-tag-project')
    },
    async proccedDelete() {
      const success = await this.deleteTagProject({
        id: this.selectedToDelete,
        tagId: this.tag.id
      })
      if (success) {
        this.closeDeleteDialog()
      }
    }
  }
}
</script>

<style></style>
