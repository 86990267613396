<template>
  <Layout>
    <div class="p-4">
      <b-row class="mt-4">
        <div class="d-flex justify-content-between">
          <div class="ml-2">
            <h1>{{ $t('menu.trademarks') }}</h1>
          </div>
          <div class="mr-3">
            <b-button @click="openModal()" variant="outline-dark"> Nuevo </b-button>
          </div>
        </div>
        <div class="separator mb-3"></div>
      </b-row>
      <b-colxx xxs="12">
        <b-card>
          <b-table
            :fields="tableTrademarks.fields"
            :items="trademarks"
            responsive
            hover
            show-empty
            empty-text="No hay marcas registradas."
          >
            <template #cell(name)="data">
              <img
                class="img__avatar mx-1"
                width="50"
                v-if="data.item.image"
                :src="data.item.image"
              />
              <span class="ml-2">
                {{ data.value }}
              </span>
            </template>
            <template #cell(action)="data">
              <div class="text-center">
                <b-button
                  pill
                  class="mx-1"
                  size="sm"
                  variant="info"
                  @click="openDetails(data.item.id)"
                >
                  <i class="simple-icon-eye"></i>
                </b-button>
                <b-button
                  pill
                  class="mx-1"
                  size="sm"
                  variant="success"
                  @click="openModal(true, data.item.id)"
                >
                  <i class="simple-icon-pencil"></i>
                </b-button>
                <b-button
                  pill
                  size="sm"
                  class="mx-1"
                  variant="danger"
                  @click="openDeleteDialog(data.item.id)"
                >
                  <i class="simple-icon-trash"></i>
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-colxx>
      <b-modal
        id="modal-trademarks"
        :title="modeEditor ? 'Editar Marca' : 'Agregar Marca'"
        modal-class="modal-right"
        @close="closeModal"
        no-close-on-backdrop
        hide-footer
      >
        <b-container fluid>
          <b-row>
            <b-colxx xxs="12" class="d-flex align-items-center justify-content-center">
              <b-avatar
                :src="
                  selectedImg
                    ? selectedImg
                    : form.imageUrl
                    ? form.imageUrl
                    : require('@/assets/images/app/panels/panel.jpg')
                "
                square
                size="7rem"
                class="img__avatar"
                badge
                id="avatar-trademark"
                badge-variant="light"
              >
                <template #badge>
                  <b-button
                    v-if="selectedImg"
                    class="px-2"
                    size="sm"
                    variant="danger"
                    @click="removeImgSelected"
                  >
                    <i class="simple-icon-trash"></i>
                  </b-button>
                  <b-button class="px-2" size="sm" variant="primary" @click="pickFile">
                    <i class="simple-icon-cloud-upload"></i>
                  </b-button>
                </template>
              </b-avatar>
            </b-colxx>
            <b-colxx xss="12" class="mt-3">
              <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
                <input
                  ref="inputFile"
                  @change="onFilePicked"
                  type="file"
                  style="display: none"
                  accept="image/png, image/jpg, image/jpeg"
                />
                <b-form-group label="Nombre" class="has-float-label mb-4">
                  <b-form-input
                    type="text"
                    v-model="$v.form.name.$model"
                    :state="!$v.form.name.$error"
                  />
                  <b-form-invalid-feedback v-if="!$v.form.name.required">
                    El nombre de la marca es requerido.
                  </b-form-invalid-feedback>
                </b-form-group>
                <div class="d-flex justify-content-end">
                  <b-button
                    type="submit"
                    variant="outline-dark"
                    size="md"
                    :disabled="processing"
                    :class="{
                      'btn-multiple-state btn-shadow': true,
                      'show-spinner': processing
                    }"
                  >
                    <span class="spinner d-inline-block">
                      <span class="bounce1"></span>
                      <span class="bounce2"></span>
                      <span class="bounce3"></span>
                    </span>
                    <span class="label"> Guardar </span>
                  </b-button>
                </div>
              </b-form>
            </b-colxx>
          </b-row>
        </b-container>
      </b-modal>
      <b-modal
        id="modal-trademark"
        title="Detalle de la Marca"
        modal-class="modal-right"
        @close="closeModalDetails"
        no-close-on-backdrop
        hide-footer
      >
        <b-container fluid>
          <b-row>
            <b-colxx xxs="12" class="d-flex justify-content-center">
              <b-avatar
                :src="trademark.image || '/assets/img/otras/panel.jpg'"
                square
                size="7rem"
                class="my-4 img-actions"
              >
              </b-avatar>
            </b-colxx>
            <b-colxx xss="12" class="mt-3">
              <p class="text-muted text-small mb-2">Nombre</p>
              <p class="mb-3">
                {{ trademark.name }}
              </p>
            </b-colxx>
          </b-row>
        </b-container>
      </b-modal>
      <BaseConfirmModal
        dialogId="delete-trademark"
        title="Eliminar Marca"
        message="¿Estas seguro de que quieres eliminar esta marca?"
        cancelText="Cancelar"
        proccedText="Aceptar"
        @close="closeDeleteDialog"
        @procced="proccedDelete"
      />
    </div>
  </Layout>
</template>
<script>
import Layout from '@/router/layouts/main'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')
export default {
  name: 'Trademarks',
  components: {
    Layout,
    BaseConfirmModal
  },
  mixins: [validationMixin],
  created() {
    this.getTrademarks()
  },
  data() {
    return {
      selectedToDelete: null,
      processing: false,
      modeEditor: false,
      form: {
        id: null,
        name: '',
        image: null,
        imageUrl: null
      },
      tableTrademarks: {
        fields: [
          {
            key: 'id',
            label: 'ID',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'name',
            label: 'Nombre',
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'action',
            label: 'Acción',
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          }
        ]
      },
      selectedImg: null
    }
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({ trademarks: 'admin/trademarks', trademark: 'admin/trademark' })
  },
  methods: {
    ...mapActions({
      getTrademarks: 'admin/getTrademarks',
      getTrademark: 'admin/getTrademark',
      createTrademark: 'admin/createTrademark',
      updateTrademark: 'admin/updateTrademark',
      deleteTrademark: 'admin/deleteTrademark'
    }),
    ...mapMutations({ setTrademark: 'admin/setTrademark' }),
    openModal(edit = false, itemId = null) {
      this.modeEditor = edit
      if (itemId) {
        const data = this.trademarks.find((item) => item.id === itemId)
        const { id, name, image } = data
        this.form = {
          id,
          name,
          imageUrl: image,
          image: null
        }
      }
      this.$bvModal.show('modal-trademarks')
    },
    closeModal() {
      this.modeEditor = false
      this.$nextTick(() => {
        this.form = {
          id: null,
          name: '',
          image: null,
          imageUrl: null
        }
        this.$v.form.$reset()
        this.$bvModal.hide('modal-trademarks')
      })
    },
    async openDetails(itemId) {
      const success = await this.getTrademark({
        id: itemId
      })
      if (success) {
        this.$bvModal.show('modal-trademark')
      }
    },
    closeModalDetails() {
      this.setTrademark({})
      this.$bvModal.hide('modal-trademark')
    },
    async formSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { id, ...rest } = this.form
        let success
        if (id) {
          success = await this.updateTrademark({
            id,
            body: { ...rest }
          })
        } else {
          success = await this.createTrademark({
            body: { ...rest }
          })
        }
        this.processing = false
        if (success) {
          this.removeImgSelected()
          this.closeModal()
        }
      }
    },
    removeImgSelected() {
      this.form.image = null
      this.selectedImg = null
    },
    pickFile() {
      this.$refs.inputFile.click()
    },
    onFilePicked(e) {
      const { files } = e.target
      const fr = new FileReader()
      if (files[0] !== undefined) {
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.form.image = files[0]
          this.selectedImg = fr.result
        })
      }
    },
    openDeleteDialog(itemId) {
      this.selectedToDelete = itemId
      this.$bvModal.show('delete-trademark')
    },
    closeDeleteDialog() {
      this.selectedToDelete = null
      this.$bvModal.hide('delete-trademark')
    },
    async proccedDelete() {
      const success = await this.deleteTrademark({
        id: this.selectedToDelete
      })
      if (success) {
        this.closeDeleteDialog()
      }
    }
  }
}
</script>

<style>
#avatar-trademark > span.b-avatar-badge.badge-light {
  padding: 0 0 0.2rem 0 !important;
  background-color: transparent;
  min-height: auto;
  min-width: auto;
}
</style>
