<template>
  <Layout>
    <div class="p-4">
      <b-row>
        <b-colxx xxs="12" class="ml-2 mr-3">
          <b-row align-h="between" class="">
            <div class="d-flex justify-content-between">
              <h1>Tickets de Soporte</h1>
              <div class="separator mb-4"></div>
              <div class="mr-3">
                <b-button @click="openModal()" v-superadmin="true" variant="outline-dark">
                  Nuevo
                </b-button>
              </div>
            </div>
          </b-row>
          <div class="separator mb-2"></div>
        </b-colxx>
        <b-colxx xxs="12" class="mt-3">
          <b-card no-body>
            <b-card-text>
              <b-table
                :fields="ticketsTable.fields"
                :items="tickets"
                responsive
                hover
                show-empty
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                sort-icon-left
                :empty-text="$t('validation.empty_tickets')"
              >
                <template #head()="{ label, field: { key, sortable } }">
                  {{ label }}
                  <template v-if="sortable">
                    <!-- Custom icons -->
                    <template>
                      <Icon v-if="sortBy !== key" :icon="sortIconNeutral"></Icon>
                      <Icon v-else-if="sortDesc" :icon="sortIconAsc"></Icon>
                      <Icon v-else :icon="sortIconDesc"></Icon>
                    </template>
                  </template>
                </template>
                <template #cell(status_name)="{ item }">
                  <div class="mt-1">
                    <center>
                      <b-badge
                        :variant="item.status === STATUS_TICKET_ACTIVE ? 'primary' : 'danger'"
                      >
                        <span class="" style="font-size: 14px">
                          {{ item.status_name }}
                        </span></b-badge
                      >
                    </center>
                  </div>
                </template>
                <template #cell(last_message)="{ item }">
                  <div class="mt-1">
                    <center>
                      <span>
                        {{ item.last_message | moment('DD-MM-YYYY, h:mm a') }}
                      </span>
                    </center>
                  </div>
                </template>
                <template #cell(action)="data">
                  <div class="text-center">
                    <b-button
                      class="mx-1"
                      pill
                      size="sm"
                      variant="info"
                      @click="goToDetailsTicket(data.item.id)"
                    >
                      <i class="simple-icon-eye"></i>
                    </b-button>
                    <b-button
                      pill
                      class="mx-1"
                      size="sm"
                      variant="success"
                      v-superadmin="true"
                      @click="openModal(true, data.item.id)"
                    >
                      <i class="simple-icon-pencil"></i>
                    </b-button>
                    <b-button
                      pill
                      class="mx-1"
                      size="sm"
                      variant="danger"
                      v-superadmin="true"
                      @click="openDeleteDialog(data.item.id)"
                    >
                      <i class="simple-icon-trash"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-card-text>
          </b-card>
        </b-colxx>
      </b-row>
      <b-modal
        id="modal-tickets"
        :title="modeEditor ? `${$t('survey.edit')} Ticket` : `${$t('forms.add')} Ticket`"
        modal-class="modal-right"
        @close="closeModal"
        no-close-on-backdrop
        hide-footer
      >
        <b-container fluid>
          <b-row>
            <b-colxx xss="12" class="mt-3">
              <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
                <b-form-group label="Solicitado por:" class="w-100 has-float-label">
                  <v-select
                    class="w-100 mt-1 select__custom mb-4"
                    v-model="$v.form.user_id.$model"
                    :state="!$v.form.user_id.$error"
                    :reduce="(user) => user.id"
                    placeholder="Seleccione un usuario"
                    :options="users"
                    label="fullname_with_email"
                  >
                  </v-select>
                  <b-form-invalid-feedback v-if="!$v.form.user_id.required">
                    El usuario es requerido.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Usuario que atenderá el ticket:"
                  class="w-100 has-float-label"
                >
                  <v-select
                    class="w-100 mt-1 select__custom mb-4"
                    v-model="$v.form.user_to_assign_id.$model"
                    :state="!$v.form.user_to_assign_id.$error"
                    :reduce="(user) => user.id"
                    placeholder="Seleccione el usuario que atenderá el ticket.."
                    :options="users"
                    label="fullname_with_email"
                  >
                  </v-select>
                  <b-form-invalid-feedback v-if="!$v.form.user_id.required">
                    El usuario es requerido.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="Asunto" class="has-float-label mb-4">
                  <b-form-input
                    type="text"
                    v-model="$v.form.title.$model"
                    :state="!$v.form.title.$error"
                  />
                  <b-form-invalid-feedback v-if="!$v.form.title.required">
                    {{ $t('validation.name_required') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Categoría de Ticket" class="w-100 has-float-label mb-4">
                  <b-form-select
                    v-model="$v.form.type.$model"
                    :state="!$v.form.type.$error"
                    :options="categoriesTickets"
                    value-field="id"
                    text-field="name"
                    class="w-100"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled>
                        Seleccione una categoría...
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback v-if="!$v.form.type.required">
                    La Categoría es requerida.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  v-if="!modeEditor"
                  label="Descripción del problema"
                  class="has-float-label mb-4"
                >
                  <textarea
                    class="form-control mt-2"
                    id="commentmessage-input"
                    placeholder="Descripción del problema"
                    rows="3"
                    v-model="$v.form.content.$model"
                    :maxlength="255"
                  ></textarea>
                  <b-form-invalid-feedback v-if="!$v.form.content.required">
                    {{ $t('validation.content_required') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  v-if="!modeEditor"
                  label="Adjuntar Archivo"
                  class="has-float-label mb-4"
                >
                  <div class="mt-3">
                    <input
                      @change="onFilePicked"
                      class="form-control"
                      type="file"
                      id="formFile"
                    />
                  </div>
                </b-form-group>
                <div class="d-flex justify-content-end">
                  <b-button
                    type="submit"
                    variant="outline-dark"
                    size="md"
                    :disabled="processing"
                    :class="{
                      'btn-multiple-state btn-shadow': true
                    }"
                  >
                    <b-spinner v-if="processing" class="mx-2" small></b-spinner>
                    <span class="label">
                      {{ $t('survey.save') }}
                    </span>
                  </b-button>
                </div>
              </b-form>
            </b-colxx>
          </b-row>
        </b-container>
      </b-modal>
      <BaseConfirmModal
        dialogId="delete-ticket"
        :title="`${$t('pages.delete')} Ticket`"
        :message="`¿ ${$t('general.question_delete')} Ticket ?`"
        :cancelText="$t('pages.cancel')"
        :proccedText="$t('button.accept')"
        @close="closeDeleteDialog"
        @procced="proccedDelete"
      />
    </div>
  </Layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseConfirmModal from '@/components/Common/BaseConfirmModal'
import adminMixin from '@/mixins/admin'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')
import Layout from '@/router/layouts/main'
import { STATUS_TICKET_CLOSED, STATUS_TICKET_ACTIVE } from '@/constants/ticket'
import { sortIconNeutral, sortIconDesc, sortIconAsc } from '@/constants/icons'
import Icon from '@/components/Icons/Icon.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'ListTickets',
  components: {
    BaseConfirmModal,
    Layout,
    Icon,
    'v-select': vSelect
  },
  mixins: [validationMixin, adminMixin],
  data() {
    return {
      STATUS_TICKET_CLOSED,
      STATUS_TICKET_ACTIVE,
      selectedToDelete: null,
      processing: false,
      modeEditor: false,
      statusActive: 1,
      prevUserAdminAssigned: null,
      form: {
        id: null,
        title: '',
        content: '',
        user_id: null,
        user_to_assign_id: null,
        media: null,
        type: null
      },
      sortBy: 'last_message',
      sortDesc: true,
      sortIconAsc,
      sortIconDesc,
      sortIconNeutral,
      ticketsTable: {
        fields: [
          {
            key: 'title',
            label: this.$t('forms.title'),
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'type.name',
            label: this.$t('survey.category'),
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'user_fullname',
            label: this.$t('dashboards.user'),
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'user_to_assign.fullname',
            label: this.$t('forms.admin_assigned'),
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-white'
          },
          {
            key: 'status_name',
            label: this.$t('survey.status'),
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          },
          {
            key: 'last_message',
            label: this.$t('survey.last_message'),
            sortable: true,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          },
          {
            key: 'action',
            label: this.$t('dropdowns.action'),
            sortable: false,
            tdClass: 'text-muted',
            thClass: 'text-center text-white'
          }
        ]
      }
    }
  },
  validations: {
    form: {
      title: {
        required
      },
      content: {
        required
      },
      type: {
        required
      },
      user_id: {
        required
      },
      user_to_assign_id: {
        required
      }
    }
  },
  mounted() {
    this.getUsers()
    this.getCategories()
    this.getTickets()
  },
  computed: {
    ...mapGetters({
      tickets: 'ticket/tickets',
      users: 'project/users',
      categoriesTickets: 'ticket/categoriesTickets'
    })
  },
  methods: {
    ...mapActions({
      getUsers: 'project/getUsers',
      getTickets: 'ticket/getTickets',
      getCategories: 'ticket/getCategoriesTickets',
      createTicket: 'ticket/createTicket',
      updateTicket: 'ticket/updateTicket',
      deleteTicket: 'ticket/deleteTicket'
    }),
    onFilePicked(e) {
      const { files } = e.target
      const fr = new FileReader()
      if (files[0] !== undefined) {
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.form.media = files[0]
        })
      }
    },
    goToDetailsTicket(id) {
      this.$router.push({ name: 'showTicket', params: { id } })
    },
    openModal(edit = false, itemId = null) {
      this.modeEditor = edit
      if (itemId) {
        const data = this.tickets.find((item) => item.id === itemId)
        const { id, title, type, customuser, user_to_assign } = data
        this.prevUserAdminAssigned = user_to_assign.id
        this.form = {
          id,
          title,
          content: 'content',
          type: type.id,
          user_id: customuser.id,
          user_to_assign_id: user_to_assign.id
        }
      }
      this.$bvModal.show('modal-tickets')
    },
    closeModal() {
      this.modeEditor = false
      this.$nextTick(() => {
        this.form = {
          id: null,
          title: '',
          content: '',
          type: null,
          media: null,
          user_id: null,
          user_to_assign_id: null
        }
        this.$v.form.$reset()
        this.$bvModal.hide('modal-tickets')
      })
    },
    async formSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.processing = true
        const { id, ...rest } = this.form
        let success

        if (id) {
          success = await this.updateTicket({
            id,
            body: {
              ...rest,
              customuser: rest.user_id,
              status: this.statusActive,
              prevUserAdminAssigned: this.prevUserAdminAssigned
            }
          })
        } else {
          success = await this.createTicket({
            body: {
              ...rest,
              customuser: rest.user_id,
              status: this.statusActive
            }
          })
        }
        this.processing = false
        if (success) {
          this.closeModal()
        }
      }
    },
    openDeleteDialog(itemId) {
      this.selectedToDelete = itemId
      this.$bvModal.show('delete-ticket')
    },
    closeDeleteDialog() {
      this.selectedToDelete = null
      this.$bvModal.hide('delete-ticket')
    },
    async proccedDelete() {
      const success = await this.deleteTicket({
        id: this.selectedToDelete
      })
      if (success) {
        this.closeDeleteDialog()
      }
    }
  }
}
</script>

<style>
#modal-category.modal-right .modal-dialog {
  max-width: 60vw;
}
</style>
